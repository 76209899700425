import { _createEl } from '../../common/_create-el';
import { _isNil } from '../../common/_types';
import './__results-section.scss';

export function layoutSearchResultsSection(data) {
    const resultEl = document.createDocumentFragment();
    const headlineContent = [
        _createEl('div', 'layout-search__results-title', [data.title]),
    ];

    if (!_isNil(data.showAllUrl)) {
        headlineContent.unshift(_createEl('div', 'layout-search__results-link', [
            _createEl('a', { href: data.showAllUrl }, [data.showAllLabel]),
        ]));
    }

    resultEl.appendChild(_createEl('div', 'layout-search__results-headline', headlineContent));
    resultEl.appendChild(data.content);

    return resultEl;
}
