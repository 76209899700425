import components from '../../common/_components';
import { _isElementVisible, _toArray } from '../../common/_core';
import { _on } from '../../common/_events';
import HeadersHeightManager from '../../common/_headers-height-manager';
import {
    Animation,
    scroll as scrollPage,
    stop as stopScrollingPage,
    isInProgress as isScrollingPageInProgress,
} from '../../common/_scroll-page';
import { lockPageScrolling, unlockPageScrolling } from '../../common/_scroll-page-lock';
import StickyElement from '../../common/_sticky-element';

import './layout-description.scss';

const ELEMENT_OFFSET = 15;
const HASH_SCROLL_DURATION = 400;

const blockName = 'layout-description';
const buttonActiveClassName = 'active';
const linkActiveClassName = 'active';
const asideOpenedClassName = 'opened';

class LayoutTextIndex {
    constructor(el) {
        this._el = el;
        this._asideEl = el.querySelector(`.js-${blockName}__aside`);
        this._asideFixedEl = el.querySelector(`.js-${blockName}__aside-fixed`);
        this._asideListEl = this._asideFixedEl.querySelector('ul');
        this._buttonEl = el.querySelector(`.js-${blockName}__button`);
        this._list = _toArray(this._asideListEl.querySelectorAll('a')).map((linkEl) => {
            const selector = linkEl.getAttribute('href');
            const headerEl = document.getElementById(selector.replace('#', ''));

            _on(linkEl, 'click', (e) => {
                scrollPage(this._getScrollTop(headerEl), { duration: 0 });
                e.preventDefault();
            });

            return {
                linkEl,
                headerEl,
            };
        });

        _on(window, 'scroll', this._scrollHandler);
        _on(window, 'resize', this._resizeHandler);
        _on(window, 'lazyloaded', this._lazyLoadedHandler);
        _on(this._buttonEl, 'click', this._buttonClickHandler);

        this._check();
        this._updateStickyElements();
        this._checkHash();
    }

    _scrollHandler = () => {
        this._close();
        this._check();
    };

    _resizeHandler = () => {
        this._close();
        this._updateStickyElements();
        this._check();
    };

    _lazyLoadedHandler = () => {
        if (this._hashScrollOption && isScrollingPageInProgress()) {
            stopScrollingPage();

            scrollPage(this._getScrollTop(this._hashScrollOption.targetEl), {
                duration: Math.max(this._hashScrollOption.endTime - Date.now(), 0),
                onStop: this._onHashScrollStop,
            });
        } else {
            this._check();
        }
    };

    _updateStickyElements = () => {
        const asideElRect = this._asideEl.getBoundingClientRect();

        if (asideElRect.width > 0 && asideElRect.height > 0) {
            if (this._buttonSticky) {
                this._buttonSticky.destroy();
                this._buttonSticky = null;
            }

            if (!this._asideSticky) {
                const asideHolderEl = this._asideEl.querySelector(`.js-${blockName}__aside-holder`);
                let fixedElScrollTop;

                this._asideSticky = new StickyElement(this._asideFixedEl, asideHolderEl, {
                    restrictionAreaEl: this._asideEl,
                    pushWithHeader: true,
                    pushedHeaderOffset: 15,
                    beforeStateChange: () => {
                        fixedElScrollTop = this._asideListEl.scrollTop;
                    },
                    afterStateChange: () => {
                        if (fixedElScrollTop) {
                            this._asideListEl.scrollTop = fixedElScrollTop;
                            fixedElScrollTop = null;
                        }
                    },
                });
            }
        } else {
            if (this._asideSticky) {
                this._asideSticky.destroy();
                this._asideSticky = null;
            }

            if (!this._buttonSticky) {
                const buttonHolderEl = this._el.querySelector(`.js-${blockName}__button-holder`);
                const buttonFixedEl = this._el.querySelector(`.js-${blockName}__button-fixed`);

                this._buttonSticky = new StickyElement(buttonFixedEl, buttonHolderEl, {
                    restrictionAreaEl: this._el,
                    pushWithHeader: false,
                });
            }
        }
    };

    _buttonClickHandler = () => {
        if (!this._isOpened) {
            this._open();
        } else {
            this._close();
        }
    };

    _open = () => {
        if (!this._isOpened) {
            lockPageScrolling(this._asideListEl);

            this._asideFixedEl.classList.add(asideOpenedClassName);
            this._buttonEl.classList.add(buttonActiveClassName);

            const windowHeight = document.documentElement.clientHeight;
            const headerHeight = HeadersHeightManager.getHeight();
            const fixedPanelHeight = this._asideFixedEl.getBoundingClientRect().height;
            const topOffset = (windowHeight - headerHeight - fixedPanelHeight) / 2;

            this._asideFixedEl.style.top = `${headerHeight + topOffset}px`;
            this._scrollToItem(this._currentActiveItem, false);

            this._isOpened = true;
        }
    };

    _close = () => {
        if (this._isOpened) {
            this._asideFixedEl.classList.remove(asideOpenedClassName);
            this._asideFixedEl.style.top = '';

            this._buttonEl.classList.remove(buttonActiveClassName);

            unlockPageScrolling(this._asideListEl);
            this._isOpened = false;
        }
    };

    _check = () => {
        const headerHeight = HeadersHeightManager.getHeight();

        let currentIndexItem = this._list[0];

        for (let i = 0; i < this._list.length; i += 1) {
            const indexItem = this._list[i];
            const headerRect = indexItem.headerEl.getBoundingClientRect();

            if (headerRect.top - (ELEMENT_OFFSET + 1) - headerHeight > 0) {
                break;
            }

            currentIndexItem = indexItem;
        }

        if (this._currentActiveItem !== currentIndexItem) {
            this._currentActiveItem = currentIndexItem;

            this._list.forEach((indexItem) => {
                if (indexItem === currentIndexItem) {
                    indexItem.linkEl.classList.add(linkActiveClassName);
                } else {
                    indexItem.linkEl.classList.remove(linkActiveClassName);
                }
            });

            this._scrollToItem(currentIndexItem);
        }
    };

    _scrollToItem = (currentIndexItem, animate = true) => {
        const fixedPanelScrollTop = this._asideListEl.scrollTop;
        const fixedPanelScrollHeight = this._asideListEl.scrollHeight;
        const fixedPanelRect = this._asideListEl.getBoundingClientRect();

        if (fixedPanelScrollHeight > fixedPanelRect.height) {
            const linkRect = currentIndexItem.linkEl.getBoundingClientRect();
            const centerOfLink = fixedPanelScrollTop + (linkRect.top - fixedPanelRect.top) + linkRect.height / 2;

            let scrollTo = centerOfLink - fixedPanelRect.height / 2;

            if (fixedPanelScrollTop < 0) {
                scrollTo = 0;
            } else if (fixedPanelScrollTop > fixedPanelScrollHeight - fixedPanelRect.height) {
                scrollTo = fixedPanelScrollHeight - fixedPanelRect.height;
            }

            if (scrollTo !== fixedPanelScrollTop) {
                if (this._animation) {
                    this._animation.stop();
                }

                if (animate) {
                    this._animation = new Animation(this._asideListEl, { scrollTop: scrollTo });
                } else {
                    this._asideListEl.scrollTop = scrollTo;
                }
            }
        }
    };

    _getScrollTop = (el) => {
        const headerOffsetTop = window.pageYOffset + el.getBoundingClientRect().top;
        return headerOffsetTop - ELEMENT_OFFSET - HeadersHeightManager.getHeight();
    };

    _checkHash = () => {
        const hash = window.location.hash.slice(1);

        if (!hash) {
            return;
        }

        const targetEl = document.getElementById(hash);

        if (!targetEl || !_isElementVisible(targetEl)) {
            return;
        }

        this._hashScrollOption = {
            targetEl,
            endTime: Date.now() + HASH_SCROLL_DURATION,
        };

        scrollPage(this._getScrollTop(targetEl), {
            jump: 100,
            duration: HASH_SCROLL_DURATION,
            onStop: this._onHashScrollStop,
        });
    };

    _onHashScrollStop = () => {
        if (this._hashScrollOption && this._hashScrollOption.endTime - Date.now() < 10) {
            this._hashScrollOption = null;
        }
    };

    static installTo(asideFixedEl, buttonEl) {
        return new LayoutTextIndex(asideFixedEl, buttonEl);
    }
}

components.add(`js-${blockName}`, el => new LayoutTextIndex(el));
