import { _isElementVisible } from '../../common/_core';
import { _off, _on, _trigger } from '../../common/_events';
import { throttle } from '../../vendors/_throttle-debounce';
import './range-slider.scss';

const blockName = 'range-slider';
const disabledMod = 'disabled';
const hiddenMod = 'hidden';
const activeMod = 'active';

const startEventsList = ['mousedown', 'touchstart', 'pointerdown'];
const moveEventsList = ['mousemove', 'touchmove', 'pointermove'];
const endEventsList = ['mouseup', 'touchend', 'pointerup'];

const DIMENSION = 'width';
const DIRECTION = 'left';
const DIRECTION_STYLE = 'left';
const COORDINATE = 'x';

let counter = 0;

class RangeSlider {
    constructor(inputEl, labelEl, rawOptions = {}) {
        counter += 1;

        this._id = `js-${blockName}-${counter}`;
        this._decoratedUpdated = throttle(300, this.update);
        this._options = {
            ...{
                maxValue: Infinity,
                maxValueLabel: '',
                currency: '',
                onInit: () => {},
                onSlide: () => {},
                onSlideEnd: () => {},
            },
            ...rawOptions,
        };

        this._inputEl = inputEl;
        this._labelEl = labelEl;

        this._fillEl = this._createEl(`${blockName}__fill`);
        this._handleEl = this._createEl(`${blockName}__handle`);
        this._rangeEl = this._createEl(blockName);

        this._rangeEl.appendChild(this._fillEl);
        this._rangeEl.appendChild(this._handleEl);

        inputEl.style.position = 'absolute';
        inputEl.style.width = '1px';
        inputEl.style.height = '1px';
        inputEl.style.overflow = 'hidden';
        inputEl.style.opacity = '0';

        this._insertAfter(this._inputEl, this._rangeEl);
        this._updateFromAttributes();

        this.update();
        this._options.onInit();

        startEventsList.forEach((eventName) => { _on(this._rangeEl, eventName, this._handleDown); });
        _on(window, 'resize', this._decoratedUpdated);
        _on(this._inputEl, 'change', this._changeHandler);
    }

    update = () => {
        if (_isElementVisible(this._inputEl)) {
            this._rangeEl.classList[this._inputEl.disabled ? 'add' : 'remove'](disabledMod);
            this._rangeEl.classList.remove(hiddenMod);

            this._handleDimension = this._handleEl.offsetWidth;
            this._handleOffset = this._handleDimension / 2;
            this._maxHandlePos = this._rangeEl.offsetWidth - this._handleDimension;

            this._setPosition(this._getPositionFromValue(this._value), false);
        } else {
            this._rangeEl.classList.add(hiddenMod);
        }
    };

    setValue = (value) => {
        if (String(value) !== String(this._value)) {
            const position = this._getPositionFromValue(value);

            if (!isNaN(position)) { // eslint-disable-line no-restricted-globals
                this._setPosition(position);
            } else {
                const processedValue = this._ensureValidValue(value);

                this._setInputValue(processedValue);
                this._value = processedValue;
                this._updateLabel(processedValue);
            }
        }
    };

    getValue = () => this._value;

    destroy = () => {
        startEventsList.forEach((eventName) => { _off(this._rangeEl, eventName, this.handleDown); });
        moveEventsList.forEach((eventName) => { _off(document, eventName, this.handleMove); });
        endEventsList.forEach((eventName) => { _off(document, eventName, this.handleEnd); });

        _off(this._inputEl, 'change', this._changeHandler);
        _off(window, 'resize', this._decoratedUpdated);

        if (this._rangeEl && this._rangeEl.parentNode) {
            this._rangeEl.parentNode.removeChild(this._rangeEl);
        }
    };

    _createEl = (className) => {
        const div = document.createElement('div');
        div.className = className;
        return div;
    };

    _insertAfter = (el, newEl) => {
        const nextEl = this._getNextElement(el);
        const parentEl = el.parentNode;

        if (nextEl) {
            parentEl.insertBefore(newEl, nextEl);
        } else {
            parentEl.appendChild(newEl);
        }
    };

    _getNextElement = (el) => {
        do {
            el = el.nextSibling;
        } while (el && el.nodeType !== 1);

        return el;
    };

    _updateFromAttributes = () => {
        this._min = this._tryParseFloat(this._inputEl.getAttribute('min'), 0);
        this._max = this._tryParseFloat(this._inputEl.getAttribute('max'), 100);
        this._value = this._tryParseFloat(this._inputEl.value, Math.round(this._min + (this._max - this._min) / 2));
        this._step = this._tryParseFloat(this._inputEl.getAttribute('step'), 1);
    };

    _updateLabel = (value) => {
        const { maxValueLabel, maxValue, currency } = this._options;
        const text = this._labelEl.textContent;
        const isMaxValue = text === maxValueLabel && value === maxValue;
        const valueNotChanged = (parseInt(text, 10) === value);

        if (!isMaxValue && !valueNotChanged) {
            this._labelEl.textContent = (value < maxValue) ? `${value}${currency}` : maxValueLabel;
        }
    };

    _changeHandler = (e) => {
        if (e.detail && e.detail.origin === this._id) {
            return;
        }

        this._setPosition(this._getPositionFromValue(e.target.value));
    };

    _handleDown = (e) => {
        e.preventDefault();

        if (e.button && e.button !== 0) { return; }

        moveEventsList.forEach((eventName) => { _on(document, eventName, this._handleMove); });
        endEventsList.forEach((eventName) => { _on(document, eventName, this._handleEnd); });

        this._rangeEl.classList.add(activeMod);

        if (e.target === this._handleEl || this._handleEl.contains(e.target)) {
            return;
        }

        const pos = this._getRelativePosition(e);
        const rangePos = this._rangeEl.getBoundingClientRect()[DIRECTION];
        const handlePos = this._getPositionFromNode(this._handleEl) - rangePos;
        const setPos = pos - this._handleOffset;

        this._setPosition(setPos);

        if (pos >= handlePos && pos < handlePos + this._handleDimension) {
            this._handleOffset = pos - handlePos;
        }
    };

    _getPositionFromNode = (node) => {
        let i = 0;

        while (node !== null) {
            i += node.offsetLeft;
            node = node.offsetParent;
        }
        return i;
    };

    _getRelativePosition = (e) => {
        const propName = `client${COORDINATE.toUpperCase()}`;
        const rangePos = this._rangeEl.getBoundingClientRect()[DIRECTION];

        let pageCoordinate = 0;

        if (typeof e[propName] !== 'undefined') {
            pageCoordinate = e[propName];
        } else if (e.touches && e.touches[0] && typeof e.touches[0][propName] !== 'undefined') {
            pageCoordinate = e.touches[0][propName];
        } else if (e.currentPoint && typeof e.currentPoint[COORDINATE] !== 'undefined') {
            pageCoordinate = e.currentPoint[COORDINATE];
        }

        return pageCoordinate - rangePos;
    };

    _handleMove = (e) => {
        e.preventDefault();
        this._setPosition(this._getRelativePosition(e) - this._handleOffset);
    };

    _handleEnd = (e) => {
        e.preventDefault();

        moveEventsList.forEach(eventName => _off(document, eventName, this._handleMove));
        endEventsList.forEach(eventName => _off(document, eventName, this._handleEnd));

        this._rangeEl.classList.remove(activeMod);

        _trigger(this._inputEl, 'change', { origin: this._id });

        this._updateLabel(this._value);
        this._options.onSlideEnd(this._position, this._value);
    };

    _setPosition = (pos, triggerSlide = true) => {
        const value = this._getValueFromPosition(this._ensureValidPosition(pos));
        const newPos = this._getPositionFromValue(value);

        this._fillEl.style[DIMENSION] = `${newPos + this._handleOffset}px`;
        this._handleEl.style[DIRECTION_STYLE] = `${newPos}px`;
        this._setInputValue(value);

        this._position = newPos;
        this._value = value;

        if (triggerSlide) {
            this._updateLabel(value);
            this._options.onSlide(newPos, value);
        }
    };

    _ensureValidPosition = (position) => {
        if (position < 0) {
            return 0;
        }

        if (position > this._maxHandlePos) {
            return this._maxHandlePos;
        }

        return position;
    };

    _ensureValidValue = (value) => {
        if (value < this._min) {
            return this._min;
        }

        if (value > this._max) {
            return this._max;
        }

        return value;
    };

    _getPositionFromValue = (value) => {
        const percentage = (value - this._min) / (this._max - this._min);
        return (!isNaN(percentage)) ? percentage * this._maxHandlePos : 0; // eslint-disable-line no-restricted-globals
    };

    _getValueFromPosition = (pos) => {
        const percentage = pos / (this._maxHandlePos || 1);
        const value = this._step * Math.round(percentage * (this._max - this._min) / this._step) + this._min;
        return Number((value).toFixed(3));
    };

    _setInputValue = (value) => {
        if (value === this._value && this._inputEl.value !== '') {
            return;
        }

        this._inputEl.value = value;
        _trigger(this._inputEl, 'input', { origin: this._id });
    };

    _tryParseFloat = (str, defaultValue) => {
        const value = parseFloat(str);
        return isNaN(value) ? defaultValue : value; // eslint-disable-line no-restricted-globals
    };

    static installTo = (inputEl, labelEl, rawOptions) => new RangeSlider(inputEl, labelEl, rawOptions);
}

export default RangeSlider;
