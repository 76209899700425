import components from '../../common/_components';
import { _closest, _getData, _isElementVisible } from '../../common/_core';
import { _on } from '../../common/_events';
import { Animation } from '../../common/_scroll-page';
import CategoriesStore from './__store-categories';
import ShopsStore from './__store-shops';
import OffersStore from './__store-offers';
import MinimalOrderStore from './__store-minimal-order';
import SortingStore from './__store-sorting';
import { updateUrl } from './__utils';
import elementHtml from './__applied-filters-html';

const TAG_TYPE_CATEGORY = 'category';
const TAG_TYPE_SHOP = 'shop';
const TAG_TYPE_OFFER = 'offer';
const TAG_TYPE_MINIMAL_ORDER = 'minimal-order';
const TAG_TYPE_SORTING = 'sorting';

const blockName = 'offers-filters-panel';
const menuClass = `${blockName}__menu`;
const menuIsCollapsedMod = `${menuClass}_is-collapsed`;
const tagClass = `${blockName}__tag`;

class OffersFiltersPanelAppliedFilters {
    static instance = null;

    static toggleVisibility = () => {
        const createdInstance = OffersFiltersPanelAppliedFilters.instance;

        if (createdInstance) {
            createdInstance.toggle();
        }
    };

    constructor(rootEl) {
        if (this.constructor.instance) {
            return this.constructor.instance;
        }

        this._rootEl = rootEl;
        this._menuItemEl = _closest(this._rootEl, `.js-${menuClass}`);
        this._currentHtml = '';

        this._updateHtml();
        this._attachListeners();

        _on(this._rootEl, 'click', `.js-${blockName}__tag-remove`, this._removeTagClickHandler);
        this.constructor.instance = this;
    }

    toggle = () => {
        if (this._menuItemEl.classList.contains(menuIsCollapsedMod)) {
            this.expand();
        } else {
            this.collapse();
        }
    };

    expand = () => {
        const currentHeight = this._rootEl.getBoundingClientRect().height;

        this._menuItemEl.classList.remove(menuIsCollapsedMod);
        this._rootEl.style.height = '';
        this._updateHtml();

        const targetHeight = this._rootEl.getBoundingClientRect().height;
        this._rootEl.style.height = `${currentHeight}px`;

        if (targetHeight !== currentHeight) {
            this._animateHeight(targetHeight);
        }

        this._attachListeners();
    };

    collapse = () => {
        clearTimeout(this._animateHeightTimeout);
        this._detachListeners();

        this._animateHeight(0);
        this._menuItemEl.classList.add(menuIsCollapsedMod);
    };

    render = () => {
        if (_isElementVisible(this._rootEl)) {
            const previousHeight = this._rootEl.getBoundingClientRect().height;
            const previousHtml = this._currentHtml;

            this._updateHtml();

            if (previousHtml !== this._currentHtml) {
                this._rootEl.style.height = '';

                const targetHeight = this._rootEl.getBoundingClientRect().height;

                this._rootEl.style.height = `${previousHeight}px`;
                clearTimeout(this._animateHeightTimeout);

                if (targetHeight !== previousHeight) {
                    if (this._heightAnimation) {
                        this._animateHeight(targetHeight);
                    } else {
                        this._animateHeightTimeout = setTimeout(() => this._animateHeight(targetHeight), 1500);
                    }
                }
            }
        } else {
            this._updateHtml();
        }
    };

    _attachListeners = () => {
        CategoriesStore.eventEmitter.subscribe('change', this.render);
        ShopsStore.eventEmitter.subscribe('change', this.render);
        OffersStore.eventEmitter.subscribe('change', this.render);
        MinimalOrderStore.eventEmitter.subscribe('change', this.render);
        SortingStore.eventEmitter.subscribe('change', this.render);
    };

    _detachListeners = () => {
        CategoriesStore.eventEmitter.unsubscribe('change', this.render);
        ShopsStore.eventEmitter.unsubscribe('change', this.render);
        OffersStore.eventEmitter.unsubscribe('change', this.render);
        MinimalOrderStore.eventEmitter.unsubscribe('change', this.render);
        SortingStore.eventEmitter.unsubscribe('change', this.render);
    };

    _updateHtml = () => {
        const stores = {
            [TAG_TYPE_CATEGORY]: CategoriesStore,
            [TAG_TYPE_SHOP]: ShopsStore,
            [TAG_TYPE_OFFER]: OffersStore,
            [TAG_TYPE_MINIMAL_ORDER]: MinimalOrderStore,
            [TAG_TYPE_SORTING]: SortingStore,
        };

        const tagsHtml = Object.keys(stores)
            .map((tagType) => {
                const store = stores[tagType];

                if (!store.isInDefaultState()) {
                    return elementHtml({
                        tagType,
                        tags: store.getTags(),
                    });
                }

                return '';
            })
            .join('');

        if (tagsHtml !== this._currentHtml) {
            this._rootEl.innerHTML = tagsHtml;
            this._currentHtml = tagsHtml;
        }
    };

    _animateHeight = (height) => {
        if (this._heightAnimation) {
            this._heightAnimation.stop();
        }

        this._heightAnimation = new Animation(this._rootEl, {
            height: {
                to: height,
                getter: el => el.getBoundingClientRect().height,
                setter: (el, val) => { el.style.height = `${val}px`; },
            },
        }, {
            done: () => {
                this._rootEl.style.height = '';
                this._heightAnimation = null;
            },
        });
    };

    _removeTagClickHandler = (e) => {
        const tagEl = _closest(e.target, `.js-${tagClass}`);
        const type = _getData(tagEl, 'type');

        switch (type) {
            case TAG_TYPE_CATEGORY:
                CategoriesStore.unSelectCategory(_getData(tagEl, 'id'));
                break;
            case TAG_TYPE_SHOP:
                ShopsStore.unSelectShop(_getData(tagEl, 'id'));
                break;
            case TAG_TYPE_OFFER:
                OffersStore.deleteValueFrom(_getData(tagEl, 'field'), _getData(tagEl, 'value'));
                break;
            case TAG_TYPE_MINIMAL_ORDER:
                MinimalOrderStore.toDefaultState();
                break;
            case TAG_TYPE_SORTING:
                SortingStore.toDefaultState();
                break;
            default:
        }

        updateUrl();
    }
}

components.add(`js-${blockName}__applied-filters`, el => new OffersFiltersPanelAppliedFilters(el));

export default OffersFiltersPanelAppliedFilters;
