import { _createEl } from '../../common/_create-el';

export default data => _createEl('div', 'offers-filters-panel__search', [
    _createEl('div', 'offers-filters-panel__search-icon'),
    _createEl('input', 'form-control offers-filters-panel__search-input js-offers-filters-panel__search-input', {
        'type': 'text',
        'placeholder': data.inputPlaceholder,
        'autocomplete': 'off',
        'spellcheck': 'false',
        'dir': 'auto',
        'aria-label': data.inputPlaceholder,
    }),
]);
