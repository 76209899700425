import components from '../../common/_components';
import { addScrollTrigger } from '../../common/_scroll-page-trigger';

import './versus-widget.scss';

const blockName = 'versus-widget';

components.add(`js-${blockName}`, (element) => {
    addScrollTrigger(element, { offset: 0 }, () => {
        const iframeEl = element.querySelector(`.js-${blockName}__iframe`);
        iframeEl.setAttribute('src', iframeEl.getAttribute('data-src'));
    });
});
