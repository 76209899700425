import components from '../../common/_components';
import { _on } from '../../common/_events';
import { _createEl } from '../../common/_create-el';
import LayoutSearch from '../layout-search/layout-search';
import { layoutSearchButtonSearch } from '../layout-search/__button-search';
import { layoutSearchInput } from '../layout-search/__input';
import { layoutSearchButtonClose } from '../layout-search/__button-close';
import { layoutSearchButtonRight } from '../layout-search/__button-right';
import { LayoutSearchResultsMarkupFactory } from '../layout-search/__results-markup-factory';
import { layoutSearchResultsSection } from '../layout-search/__results-section';
import { layoutSearchResultsSuggestionOffer } from '../layout-search/__results-suggestion-offer';
import { layoutSearchResultsSuggestionShop } from '../layout-search/__results-suggestion-shop';

import './layout-header-desktop.scss';

const blockName = 'layout-header-desktop';

components.add(`js-${blockName}`, (containerEl) => {
    let layoutSearch;

    _on(document, 'layout-menu__active-search-change', (e) => {
        const data = e.detail || {};

        if (data.placement === LayoutSearch.PLACEMENT_HEADER_DESKTOP) {
            if (!layoutSearch) {
                layoutSearch = new LayoutSearch(containerEl.querySelector(`.js-${blockName}__search`), {
                    formElements: [
                        { role: LayoutSearch.ELEMENT_ROLE_SUBMIT, getEl: layoutSearchButtonSearch },
                        { role: LayoutSearch.ELEMENT_ROLE_RESET, getEl: layoutSearchButtonClose },
                        { role: LayoutSearch.ELEMENT_ROLE_INPUT, getEl: layoutSearchInput },
                        { role: LayoutSearch.ELEMENT_ROLE_SUBMIT, getEl: layoutSearchButtonRight },
                    ],
                    resultsMarkupFactory: new LayoutSearchResultsMarkupFactory({
                        className: `${blockName}__search-results`,
                        innerClassName: `${blockName}__search-results-inner`,
                        onResultsReady: (resultsEl) => {
                            resultsEl.parentNode.appendChild(
                                _createEl('div', `${blockName}__search-results-arrow`),
                            );
                        },
                        section: layoutSearchResultsSection,
                        suggestions: {
                            'offers': layoutSearchResultsSuggestionOffer,
                            'shops': layoutSearchResultsSuggestionShop,
                        },
                    }),
                    placement: LayoutSearch.PLACEMENT_HEADER_DESKTOP,
                    queriesPromotion: true,
                    onReset: (type) => {
                        if (type === LayoutSearch.RESET_TYPE_BUTTON_CLICK) {
                            layoutSearch.focusInput();
                        }
                    },
                });
            }

            layoutSearch.activate();
        }
    });
});
