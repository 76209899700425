import components from '../../common/_components';
import { _on } from '../../common/_events';
import { _getData } from '../../common/_core';
import CategoriesStore from './__store-categories';
import backTemplate from './__back-el';
import categoriesTemplate from './__categories-el';

const blockName = 'offers-filters-panel';
const shadowClass = `${blockName}__with-shadow`;

class OffersFiltersPanelCategories {
    static instance = null;

    static updateShadow = () => {
        const createdInstance = OffersFiltersPanelCategories.instance;

        if (createdInstance) {
            createdInstance.updateShadow();
        }
    };

    constructor(rootEl) {
        if (this.constructor.instance) {
            return this.constructor.instance;
        }

        this._rootEl = rootEl;
        this._render();

        CategoriesStore.eventEmitter.subscribe('change', this._render);
        _on(this._rootEl, 'scroll', this.updateShadow);

        this.constructor.instance = this;
    }

    updateShadow = () => {
        const bottomPosition = this._rootEl.getBoundingClientRect().height + this._rootEl.scrollTop;
        const needsShadow = (bottomPosition !== this._rootEl.scrollHeight);

        this._rootEl.classList[needsShadow ? 'add' : 'remove'](shadowClass);
    };

    _render = () => {
        this._rootEl.innerHTML = '';

        this._rootEl.appendChild(backTemplate({ header: _getData(this._rootEl, 'header') }));
        this._rootEl.appendChild(categoriesTemplate({
            categories: CategoriesStore.getCategories(),
        }));
    };
}

components.add(`js-${blockName}__categories`, el => new OffersFiltersPanelCategories(el));

export default OffersFiltersPanelCategories;
