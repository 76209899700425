import { _createEl } from '../../common/_create-el';
import './__results-suggestion.scss';

export function layoutSearchResultsSuggestionOffer(suggestion, suggestionsData) {
    const suggestionClassName = `layout-search__results-suggestion ${suggestionsData.searchSuggestionClassName}`;
    const titleEl = _createEl('div', 'layout-search__suggestion-title');

    titleEl.innerHTML = suggestionsData.highlightFn(suggestionsData.query, suggestion.name);

    return _createEl('div', suggestionClassName, {
        'data-suggestion-name': suggestion.name,
        'data-suggestion-url': suggestion.url,
    }, [
        _createEl('div', 'layout-search__suggestion-aside', [
            _createEl('img', 'layout-search__suggestion-logo', {
                src: suggestion.logo,
                alt: suggestion.name,
            }),
        ]),
        _createEl('div', 'layout-search__suggestion-body', [
            _createEl('div', 'layout-search__suggestion-title', [titleEl]),
        ]),
    ]);
}
