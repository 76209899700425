import components from '../../common/_components';

import './post-categories-tabber.scss';

const blockName = 'post-categories-tabber';

components.add(`js-${blockName}`, (rootEl) => {
    const activeLinkEl = rootEl.querySelector(`.js-${blockName}__link.active`);

    if (activeLinkEl) {
        const componentRect = rootEl.getBoundingClientRect();

        if (rootEl.scrollWidth > componentRect.width) {
            const activeLinkRect = activeLinkEl.getBoundingClientRect();
            const activeElementLeft = activeLinkRect.left - componentRect.left;

            rootEl.scrollLeft = activeElementLeft - componentRect.width / 2 + activeLinkRect.width / 2;
        }
    }
});
