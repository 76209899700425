import components from '../../common/_components';
import { _closest, _getData, _toArray } from '../../common/_core';
import { _off, _on } from '../../common/_events';
import { Animation } from '../../common/_scroll-page';
import { _sendRequest } from '../../common/_ajax';
import { throttle } from '../../vendors/_throttle-debounce';
import Overlay from '../overlay/overlay';
import componentTemplate from './offers-filters-panel-el';
import CategoriesStore from './__store-categories';
import ShopsStore from './__store-shops';
import OffersStore from './__store-offers';
import MinimalOrderStore from './__store-minimal-order';
import SortingStore from './__store-sorting';
import OffersFiltersPanelAppliedFilters from './__applied-filters';
import OffersFiltersPanelCategories from './__categories';
import OffersFiltersPanelShopping from './__shopping';
import OffersFiltersPanelOffers from './__offers';
import { updateUrl, isMobile, setRootElement, onUrlChange, getUrlData } from './__utils';

import './offers-filters-panel.scss';

const blockName = 'offers-filters-panel';
const blockOpenedMod = `${blockName}_opened`;

const menuClass = `${blockName}__menu`;
const menuIsOpenMod = `${menuClass}_is-open`;
const menuHasChangesMod = `${menuClass}_has-changes`;
const menuForAppliedFiltersMod = `${menuClass}_for_applied-filters`;
const menuForCategoriesMod = `${menuClass}_for_categories`;
const menuForShoppingMod = `${menuClass}_for_shopping`;
const menuForOffersMod = `${menuClass}_for_offers`;

const filtersClass = `${blockName}__filters`;
const filtersInnerClass = `${blockName}__filters-inner`;
const filterClass = `${blockName}__filter`;

const controlsButtonClass = `${blockName}__controls-button`;
const controlsButtonMainApplyMod = `${controlsButtonClass}_type_main-apply`;
const controlsButtonMainResetMod = `${controlsButtonClass}_type_main-reset`;

const overlay = new Overlay({ forMobileOnly: true });
const filtersChangeCallback = [];

let isInitReady = false;
let isRendered = false;
let isAsidePanelOpened = false;

let menusList;
let rootEl;
let rootElAnimation;

function resetToDefault() {
    CategoriesStore.toDefaultState();
    ShopsStore.toDefaultState();
    OffersStore.toDefaultState();
    MinimalOrderStore.toDefaultState();
    SortingStore.toDefaultState();

    OffersFiltersPanelShopping.resetInput();
}

function updateMainButtons() {
    const arePanelStoresInDefaultState = [CategoriesStore, ShopsStore, OffersStore, MinimalOrderStore]
        .every(store => store.isInDefaultState());
    const isInDefaultState = arePanelStoresInDefaultState && SortingStore.isInDefaultState();
    const applyButtonEl = rootEl.querySelector(`.js-${controlsButtonClass}.${controlsButtonMainApplyMod}`);
    const resetButtonEl = rootEl.querySelector(`.js-${controlsButtonClass}.${controlsButtonMainResetMod}`);

    applyButtonEl.classList[arePanelStoresInDefaultState ? 'add' : 'remove']('disabled');
    resetButtonEl.classList[isInDefaultState ? 'add' : 'remove']('disabled');
}

function updateCommonControls() {
    const notInDefaultState = [
        CategoriesStore,
        ShopsStore,
        OffersStore,
        MinimalOrderStore,
        SortingStore,
    ].some(store => !store.isInDefaultState());

    menusList.forEach((menuEl) => {
        if (menuEl.classList.contains(menuForAppliedFiltersMod)) {
            menuEl.classList[notInDefaultState ? 'add' : 'remove'](menuHasChangesMod);
        }
    });

    if (isMobile()) {
        updateMainButtons();
    }
}

function updateMenuItem(stores, menuMod) {
    const menuEl = menusList.filter(currentMenuEl => currentMenuEl.classList.contains(menuMod))[0];
    const controlButtons = _toArray(menuEl.querySelectorAll(`.js-${controlsButtonClass}`));
    const areStoresInDefaultState = stores.some(store => !store.isInDefaultState());
    const haveStoresChanges = stores.every(store => !store.hasChanges());

    menuEl.classList[areStoresInDefaultState ? 'add' : 'remove'](menuHasChangesMod);
    controlButtons.forEach(buttonEl => buttonEl.classList[haveStoresChanges ? 'add' : 'remove']('disabled'));
}

function updateCategories() {
    updateMenuItem([CategoriesStore], menuForCategoriesMod);
}

function updateShops() {
    updateMenuItem([ShopsStore], menuForShoppingMod);
}

function updateOffers() {
    updateMenuItem([OffersStore, MinimalOrderStore], menuForOffersMod);
}

let shopsRequest;

function sendShopsRequest() {
    if (shopsRequest) {
        shopsRequest.abort();
    }

    ShopsStore.markCategorizedAsLoading();

    shopsRequest = _sendRequest({
        url: _getData(rootEl, 'shopsCategoriesUrl'),
        dataType: 'json',
        data: CategoriesStore.getRequestData(),
        success: (response) => {
            if (response.success) {
                ShopsStore.setCategorizedShops(response.data);
            } else {
                ShopsStore.markCategorizedAsLoadingError();
            }
        },
        error: () => {
            ShopsStore.markCategorizedAsLoadingError();
        },
    });
}

function scrollToMenuImmediately() {
    rootEl.scrollLeft = 0;
}

function scrollToMenuWithAnimation() {
    if (rootElAnimation) {
        rootElAnimation.stop();
    }

    rootElAnimation = new Animation(rootEl, { scrollLeft: 0 }, { duration: 300 });
}

function scrollToFiltersWithAnimation(menuEl) {
    const filtersEl = menuEl.querySelector(`.js-${filtersClass}`);

    if (rootElAnimation) {
        rootElAnimation.stop();
    }

    rootElAnimation = new Animation(rootEl, {
        scrollLeft: rootEl.scrollLeft + filtersEl.getBoundingClientRect().left - rootEl.getBoundingClientRect().left,
    }, {
        duration: 300,
    });
}

function resetMenuScrolling() {
    rootEl.scrollTop = 0;
}

function resetFiltersScrolling(menuEl) {
    menuEl.querySelector(`.js-${filtersInnerClass}`).scrollTop = 0;
}

function overlayClickHandler() {
    closeMobilePanel();
    updateUrl();
}

function closeMobilePanel() {
    _off(document, overlay.getCloseEvent(), overlayClickHandler);

    rootEl.classList.remove(blockOpenedMod);
    overlay.hide();
}

function openMobilePanel() {
    scrollToMenuImmediately();
    resetMenuScrolling();

    updateMainButtons();

    _on(document, overlay.getCloseEvent(), overlayClickHandler);

    overlay.show();
    rootEl.classList.add(blockOpenedMod);
}

function controlsButtonClickHandler(e) {
    const buttonEl = e._caughtTarget_;

    if (!buttonEl.classList.contains('disabled')) {
        if (buttonEl.classList.contains(controlsButtonMainResetMod)) {
            resetToDefault();
        }

        closeMobilePanel();
        updateUrl();
    }

    e.stopPropagation();
    e.preventDefault();
}

function clearLinkClickHandler(e) {
    const menuEl = _closest(e._caughtTarget_, `.js-${menuClass}`);

    if (menuEl.classList.contains(menuForAppliedFiltersMod)) {
        resetToDefault();
    } else if (menuEl.classList.contains(menuForCategoriesMod)) {
        CategoriesStore.toDefaultState();
    } else if (menuEl.classList.contains(menuForShoppingMod)) {
        ShopsStore.toDefaultState();
    } else if (menuEl.classList.contains(menuForOffersMod)) {
        OffersStore.toDefaultState();
        MinimalOrderStore.toDefaultState();
    }

    updateUrl();

    e.preventDefault();
    e.stopPropagation();
}

function onCategoryClick(filterEl) {
    if (filterEl.classList.contains('selected')) {
        CategoriesStore.unSelectCategory(_getData(filterEl, 'id'));
    } else {
        CategoriesStore.selectCategory(_getData(filterEl, 'id'));
    }
}

function onShopClick(filterEl) {
    if (filterEl.classList.contains('selected')) {
        ShopsStore.unSelectShop(_getData(filterEl, 'id'));
    } else {
        ShopsStore.selectShop(_getData(filterEl, 'id'), _getData(filterEl, 'name'));
    }
}

function onOffersClick(filterEl) {
    if (filterEl.classList.contains('selected')) {
        OffersStore.deleteValueFrom(_getData(filterEl, 'field'), _getData(filterEl, 'value'));
    } else {
        OffersStore.addValueTo(_getData(filterEl, 'field'), _getData(filterEl, 'value'));
    }
}

function filterClickHandler(e) {
    const filterEl = e._caughtTarget_;
    const menuEl = _closest(filterEl, `.js-${menuClass}`);

    if (menuEl.classList.contains(menuForCategoriesMod)) {
        onCategoryClick(filterEl);
    } else if (menuEl.classList.contains(menuForShoppingMod)) {
        onShopClick(filterEl);
    } else if (menuEl.classList.contains(menuForOffersMod)) {
        onOffersClick(filterEl);
    }

    if (!isMobile()) {
        updateUrl();
    }
}

function backButtonClickHandler(e) {
    const targetEl = e._caughtTarget_;
    const menuEl = _closest(targetEl, `.js-${menuClass}`);

    if (menuEl) {
        resetMenuScrolling();
        scrollToMenuWithAnimation();
    } else {
        closeMobilePanel();
    }

    updateUrl();

    e.preventDefault();
    e.stopPropagation();
}

function buttonClickHandler(e) {
    const buttonEl = e._caughtTarget_;
    const menuEl = _closest(buttonEl, `.js-${menuClass}`);

    if (isMobile()) {
        menusList.forEach(currentMenuEl => currentMenuEl.classList.remove(menuIsOpenMod));
        menuEl.classList.add(menuIsOpenMod);

        if (menuEl.classList.contains(menuForCategoriesMod)) {
            OffersFiltersPanelCategories.updateShadow();
        } else if (menuEl.classList.contains(menuForShoppingMod)) {
            OffersFiltersPanelShopping.resetInput();
            OffersFiltersPanelShopping.updateShadow();
        } else if (menuEl.classList.contains(menuForOffersMod)) {
            OffersFiltersPanelOffers.prepareForDisplaying();
        }

        resetFiltersScrolling(menuEl);
        scrollToFiltersWithAnimation(menuEl);

        e.preventDefault();
    } else if (menuEl.classList.contains(menuForAppliedFiltersMod) && menuEl.classList.contains(menuHasChangesMod)) {
        OffersFiltersPanelAppliedFilters.toggleVisibility();
    }
}

function render() {
    if (!isRendered) {
        const options = JSON.parse(_getData(rootEl, 'options'));

        rootEl.innerHTML = '';
        rootEl.appendChild(componentTemplate({
            header: options.header,
            menus: options.menus,
            applyButtonLabel: options.applyButtonLabel,
            resetButtonLabel: options.resetButtonLabel,
        }));

        menusList = _toArray(rootEl.querySelectorAll(`.js-${menuClass}`));

        CategoriesStore.eventEmitter.subscribe('change', () => {
            updateCategories();
            updateCommonControls();
        });
        ShopsStore.eventEmitter.subscribe('change', () => {
            updateShops();
            updateCommonControls();
        });
        OffersStore.eventEmitter.subscribe('change', () => {
            updateOffers();
            updateCommonControls();
        });
        MinimalOrderStore.eventEmitter.subscribe('change', () => {
            updateOffers();
            updateCommonControls();
        });

        SortingStore.eventEmitter.subscribe('change', updateCommonControls);

        _on(rootEl, 'click', `.js-${filterClass}`, filterClickHandler);
        _on(rootEl, 'click', `.js-${blockName}__button`, buttonClickHandler);
        _on(rootEl, 'touchstart', `.js-${blockName}__button`, buttonClickHandler);

        _on(rootEl, 'click', `.js-${controlsButtonClass}`, controlsButtonClickHandler);
        _on(rootEl, 'touchstart', `.js-${controlsButtonClass}`, controlsButtonClickHandler, { passive: true });

        _on(rootEl, 'click', `.js-${blockName}__back`, backButtonClickHandler);
        _on(rootEl, 'touchstart', `.js-${blockName}__back`, backButtonClickHandler, { passive: true });

        _on(rootEl, 'click', `.js-${blockName}__button-clear`, clearLinkClickHandler);

        components.init();

        updateOffers();
        updateShops();
        updateCategories();
        updateCommonControls();

        isRendered = true;
    }
}

function urlChangeHandler(urlData) {
    const stores = [
        CategoriesStore,
        ShopsStore,
        OffersStore,
        MinimalOrderStore,
        SortingStore,
    ];

    stores.forEach(store => store.fromUrlData(urlData));

    if (stores.some(store => store.hasChanges())) {
        stores.forEach(store => store.applyChanges());

        if (stores.some(store => !store.isInDefaultState())) {
            const data = {
                ...{},
                ...CategoriesStore.getRequestData(),
                ...ShopsStore.getRequestData(),
                ...OffersStore.getRequestData(),
                ...MinimalOrderStore.getRequestData(),
                ...SortingStore.getRequestData(),
            };

            filtersChangeCallback.forEach(cb => cb(data));
        } else {
            filtersChangeCallback.forEach(cb => cb({}));
        }
    }
}

function applyCurrentUrlData(urlData) {
    [
        CategoriesStore,
        ShopsStore,
        OffersStore,
        MinimalOrderStore,
        SortingStore,
    ].forEach((store) => {
        store.fromUrlData(urlData);
        store.applyChanges();
    });
}

components.add(`js-${blockName}`, (rootElement, initializationApi) => {
    const options = JSON.parse(_getData(rootElement, 'options'));

    rootEl = rootElement;
    setRootElement(rootElement);

    CategoriesStore.init({ categories: options.categories });
    ShopsStore.init({
        categorizedShops: options.shops,
        initialCache: options.filteredShops,
    });
    OffersStore.init({ filters: options.filters });
    MinimalOrderStore.init({
        minValue: options.minimalOrder.minValue,
        maxValue: options.minimalOrder.maxValue,
        defaultValue: options.minimalOrder.defaultValue,
        label: options.minimalOrder.tagLabel,
    });

    CategoriesStore.eventEmitter.subscribe('apply', sendShopsRequest);

    OffersFiltersPanelOffers.setOptions({
        filters: options.filters,
        minimalOrder: options.minimalOrder,
    });

    OffersFiltersPanelShopping.setOptions({
        shopsNoResults: options.shopsNoResults,
        shopsInputPlaceholder: options.shopsInputPlaceholder,
        shopsSearchUrl: options.shopsSearchUrl,
    });

    initializationApi.afterAll(() => {
        if (isMobile()) {
            if (isAsidePanelOpened) {
                render();
                openMobilePanel();
            } else {
                const resizeHandler = throttle(150, () => {
                    if (!isMobile()) {
                        _off(window, 'resize', resizeHandler);
                        render();
                    }
                });

                _on(window, 'resize', resizeHandler);
            }
        } else {
            render();
        }

        SortingStore.eventEmitter.subscribe('change', updateUrl);

        applyCurrentUrlData(getUrlData());
        onUrlChange(urlChangeHandler);
    });

    isInitReady = true;
});

function onFiltersChange(cb) {
    filtersChangeCallback.push(cb);
}

function openInAsidePanel() {
    isAsidePanelOpened = true;

    if (isInitReady) {
        render();
        openMobilePanel();
    }
}

export { onFiltersChange, openInAsidePanel };
