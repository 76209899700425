import { _getData } from '../../common/_core';
import { _on, _off, _trigger } from '../../common/_events';
import components from '../../common/_components';
import HeadersHeightManager from '../../common/_headers-height-manager';
import Headroom from '../../vendors/_headroom';
import LayoutSearch from '../layout-search/layout-search';
import { layoutSearchInput } from '../layout-search/__input';
import { layoutSearchButtonClose } from '../layout-search/__button-close';
import { layoutSearchButtonSearch } from '../layout-search/__button-search';
import { LayoutSearchResultsMarkupFactory } from '../layout-search/__results-markup-factory';
import { layoutSearchResultsSection } from '../layout-search/__results-section';
import { layoutSearchResultsSuggestionOffer } from '../layout-search/__results-suggestion-offer';
import { layoutSearchResultsSuggestionShop } from '../layout-search/__results-suggestion-shop';

import './layout-header-mobile.scss';

const blockName = 'layout-header-mobile';
const searchMod = `${blockName}_search-mode`;
const wrapperClass = `${blockName}__wrapper`;
const wrapperPinnedMod = `${blockName}__wrapper_pinned`;
const wrapperUnpinnedMod = `${blockName}__wrapper_unpinned`;

const HEADERS_HEIGHT_STATE_PINNED = 'pinned';
const HEADERS_HEIGHT_STATE_UNPINNED = 'unpinned';

components.add(`js-${blockName}`, (containerEl) => {
    const mobileWidthLimit = _getData(containerEl, 'mobileWidthLimit');
    const wrapperEl = containerEl.querySelector(`.js-${wrapperClass}`);

    let layoutSearch;

    function searchButtonClickHandler() {
        if (!layoutSearch) {
            layoutSearch = new LayoutSearch(containerEl.querySelector(`.js-${blockName}__search`), {
                formElements: [
                    { role: LayoutSearch.ELEMENT_ROLE_SUBMIT, getEl: layoutSearchButtonSearch },
                    { role: LayoutSearch.ELEMENT_ROLE_INPUT, getEl: layoutSearchInput },
                    { role: LayoutSearch.ELEMENT_ROLE_RESET, getEl: layoutSearchButtonClose },
                ],
                resultsMarkupFactory: new LayoutSearchResultsMarkupFactory({
                    className: `${blockName}__search-results`,
                    section: layoutSearchResultsSection,
                    suggestions: {
                        'offers': layoutSearchResultsSuggestionOffer,
                        'shops': layoutSearchResultsSuggestionShop,
                    },
                }),
                placement: LayoutSearch.PLACEMENT_HEADER_MOBILE,
                onReset: () => {
                    containerEl.classList.remove(searchMod);
                },
            });
        }

        containerEl.classList.add(searchMod);

        layoutSearch.emptyInput();
        layoutSearch.focusInput();
    }

    function menuButtonClickHandler(e) {
        _trigger(document, 'menu-mobile_opened');
        e.preventDefault();
    }

    HeadersHeightManager.addStateChangeHandler((panel, state) => {
        if (panel.rootEl !== wrapperEl) {
            return;
        }

        if (state === HeadersHeightManager.STATE_ACTIVATED && !panel.headroom) {
            _on(containerEl, 'click', `.js-${blockName}__search-button`, searchButtonClickHandler);
            _on(containerEl, 'click', `.js-${blockName}__menu-button`, menuButtonClickHandler);

            panel.headroom = new Headroom(wrapperEl, {
                offset: 60,
                tolerance: 10,
                classes: {
                    pinned: wrapperPinnedMod,
                    unpinned: wrapperUnpinnedMod,
                },
                onPin: () => {
                    HeadersHeightManager.dispatchUpdate(HEADERS_HEIGHT_STATE_PINNED);
                },
                onUnpin: () => {
                    HeadersHeightManager.dispatchUpdate(HEADERS_HEIGHT_STATE_UNPINNED);

                    if (layoutSearch) {
                        layoutSearch.hideResults();
                    }
                },
            });

            panel.headroom.init();

            if (layoutSearch) {
                layoutSearch.activate();
            }
        } else if (state === HeadersHeightManager.STATE_DEACTIVATED && panel.headroom) {
            panel.headroom.destroy();
            delete panel.headroom;

            _off(containerEl, 'click', `.js-${blockName}__search-button`, searchButtonClickHandler);
            _off(containerEl, 'click', `.js-${blockName}__menu-button`, menuButtonClickHandler);
        }
    });

    HeadersHeightManager.setupHeader(wrapperEl, 0, mobileWidthLimit, () => {
        if (wrapperEl.classList.contains(wrapperUnpinnedMod)) {
            return 0;
        }

        return wrapperEl.getBoundingClientRect().height;
    });
});
