import { _createEl } from '../../common/_create-el';
import { _isNil } from '../../common/_types';

export default (data) => {
    const selected = data.isSelected ? ' selected' : '';
    const attrs = {
        'data-name': data.name,
    };

    if (!_isNil(data.id)) {
        attrs['data-id'] = data.id;
    }

    if (!_isNil(data.field)) {
        attrs['data-field'] = data.field;
    }

    if (!_isNil(data.value)) {
        attrs['data-value'] = data.value;
    }

    return _createEl('div', `offers-filters-panel__filter js-offers-filters-panel__filter${selected}`, attrs, [
        _createEl('span', 'offers-filters-panel__filter-label', [data.nameWithQuery ? data.nameWithQuery : data.name]),
        _createEl('span', 'offers-filters-panel__filter-checkbox', [
            _createEl('i', 'offers-filters-panel__filter-checkbox-check fa fa-ok'),
        ]),
    ]);
};
