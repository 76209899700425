import { _createEl } from '../../common/_create-el';
import './__results-markup-factory.scss';

const blockName = 'layout-search';

export function LayoutSearchResultsMarkupFactory(options) {
    const { className, innerClassName, section: sectionTemplate, suggestions, onResultsReady } = options;
    const sections = {};

    let resultsEl;
    let resultsInnerEl;

    function getSectionEl(name) {
        if (!sections[name]) {
            const sectionEl = _createEl('div', `${blockName}__results-section`);

            if (!resultsInnerEl) {
                const innerClassNames = [`${blockName}__results-inner`];

                if (innerClassName) {
                    innerClassNames.push(innerClassName);
                }

                resultsInnerEl = _createEl('div', innerClassNames.join(' '));
                resultsEl.appendChild(_createEl('div', 'container', [
                    resultsInnerEl,
                ]));

                if (onResultsReady) {
                    onResultsReady(resultsEl);
                }
            }

            resultsInnerEl.appendChild(sectionEl);
            sections[name] = sectionEl;
        }

        return sections[name];
    }

    function replaceContent(sectionEl, newEl) {
        sectionEl.innerHTML = '';
        sectionEl.appendChild(newEl);
    }

    return {
        getResultsEl: () => {
            if (!resultsEl) {
                const resultsClassNames = [`${blockName}__results`];

                if (className) {
                    resultsClassNames.push(className);
                }

                resultsEl = _createEl('div', resultsClassNames.join(' '));
            }

            return resultsEl;
        },
        renderSuggestions: (name, commonData, suggestionsData) => {
            const suggestionsTemplate = suggestions[name];
            const fragment = document.createDocumentFragment();

            if (!suggestionsTemplate) {
                throw new Error(`Suggestion template for section "${name}" is missed`);
            }

            suggestionsData.suggestions.forEach((suggestion) => {
                fragment.appendChild(suggestionsTemplate(suggestion, suggestionsData));
            });

            replaceContent(getSectionEl(name), sectionTemplate({
                ...commonData,
                content: fragment,
            }));
        },
        renderPending: (name, commonData) => {
            replaceContent(getSectionEl(name), sectionTemplate({
                ...commonData,
                content: _createEl('div', `${blockName}__pending`, [
                    _createEl('i', `fa fa-spin fa-circle-notch ${blockName}__pending-loader`),
                ]),
            }));
        },
        renderNotFound: (name, commonData, notFoundMessage) => {
            replaceContent(getSectionEl(name), sectionTemplate({
                ...commonData,
                content: _createEl('div', `${blockName}__not-found`, [notFoundMessage]),
            }));
        },
    };
}
