import bugsnag from '../../common/_bugsnag-client';
import components from '../../common/_components';
import { _getData } from '../../common/_core';
import RangeSlider from '../range-slider/range-slider';
import OffersStore from './__store-offers';
import MinimalOrderStore from './__store-minimal-order';
import { updateUrl, isMobile } from './__utils';
import backTemplate from './__back-el';
import bodyFragmentTemplate from './__offers-el';
import filterTemplate from './__filter-el';

const blockName = 'offers-filters-panel';

class OffersFiltersPanelOffers {
    static instance = null;

    static options = null;

    static setOptions = ({ filters, minimalOrder }) => {
        OffersFiltersPanelOffers.options = {
            filters: filters.slice(),
            minimalOrderHeader: minimalOrder.header,
            minimalOrderCurrency: minimalOrder.currency,
            minimalOrderDefaultValue: minimalOrder.defaultValue,
            minimalOrderMinValue: minimalOrder.minValue,
            minimalOrderMaxValue: minimalOrder.maxValue,
            minimalOrderMaxValueLabel: minimalOrder.maxValueLabel,
        };
    };

    static prepareForDisplaying = () => {
        const createdInstance = OffersFiltersPanelOffers.instance;

        if (createdInstance) {
            createdInstance.prepareForDisplaying();
        }
    };

    constructor(rootEl) {
        if (this.constructor.instance) {
            return this.constructor.instance;
        }

        if (!this.constructor.options) {
            bugsnag.notify('OffersFiltersPanelOffers.setOptions should be called before constructor');
        }

        const options = {
            ...this.constructor.options,
        };

        const bodyFragmentEl = bodyFragmentTemplate({
            sliderHeader: options.minimalOrderHeader,
            sliderCurrency: options.minimalOrderCurrency,
            sliderDefaultValue: options.minimalOrderDefaultValue,
            sliderMinValue: options.minimalOrderMinValue,
            sliderMaxValue: options.minimalOrderMaxValue,
            sliderMaxValueLabel: options.minimalOrderMaxValueLabel,
        });

        const minimalOrderInputEl = bodyFragmentEl.querySelector(`.js-${blockName}__slider-input`);
        const minimalOrderValueEl = bodyFragmentEl.querySelector(`.js-${blockName}__slider-value-text`);

        this._rootEl = rootEl;
        this._filtersListEl = bodyFragmentEl.querySelector(`.js-${blockName}__filters-list`);

        this._renderFilters();

        rootEl.appendChild(backTemplate({ header: _getData(rootEl, 'header') }));
        rootEl.appendChild(bodyFragmentEl);

        this._slider = new RangeSlider(minimalOrderInputEl, minimalOrderValueEl, {
            maxValue: options.minimalOrderMaxValue,
            maxValueLabel: options.minimalOrderMaxValueLabel,
            currency: options.minimalOrderCurrency,
            onSlide: this._onMinimalOrderSlide,
            onSlideEnd: this._onMinimalOrderChange,
        });

        this._updateMinimalOrder();

        OffersStore.eventEmitter.subscribe('change', this._renderFilters);
        MinimalOrderStore.eventEmitter.subscribe('change', this._updateMinimalOrder);

        this.constructor.instance = this;
    }

    prepareForDisplaying = () => {
        this._slider.update();
    };

    _renderFilters = () => {
        const fragment = document.createDocumentFragment();

        this.constructor.options.filters.forEach(({ field, value, label }) => {
            fragment.appendChild(filterTemplate({
                field,
                value,
                name: label,
                isSelected: OffersStore.containsValue(field, value),
            }));
        });

        this._filtersListEl.innerHTML = '';
        this._filtersListEl.appendChild(fragment);
    };

    _updateMinimalOrder = () => {
        this._slider.setValue(MinimalOrderStore.getMinimalOrder());
    };

    _onMinimalOrderSlide = () => {
        MinimalOrderStore.setMinimalOrder(this._slider.getValue());
    };

    _onMinimalOrderChange = () => {
        if (!isMobile()) {
            updateUrl();
        }
    };
}

components.add(`js-${blockName}__offers`, el => new OffersFiltersPanelOffers(el));

export default OffersFiltersPanelOffers;
