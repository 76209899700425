import bugsnag from '../../common/_bugsnag-client';
import { _parseQueryString, _stringify } from '../../common/_ajax';
import { _getCSSProp } from '../../common/_core';
import { _on } from '../../common/_events';
import { _replaceState } from '../../common/_history';
import OffersStore from './__store-offers';
import CategoriesStore from './__store-categories';
import SortingStore from './__store-sorting';
import ShopsStore from './__store-shops';
import MinimalOrderStore from './__store-minimal-order';

const urlChangeHandlers = [];
let rootEl;
let currentPathname;

function isMobile() {
    if (!rootEl) {
        bugsnag.notify('apply-filters has not been initialized properly');
        return undefined;
    }

    return _getCSSProp(rootEl, 'position') === 'fixed';
}

function setRootElement(element) {
    rootEl = element;
}

function updateUrl() {
    const urlData = getUrlData();
    let queryString;

    [CategoriesStore, ShopsStore, OffersStore, MinimalOrderStore, SortingStore].forEach((store) => {
        const storeUrlData = store.getUrlData();

        store.getValidUrlDataKeys().forEach((key) => {
            if (storeUrlData[key]) {
                urlData[key] = storeUrlData[key];
            } else {
                delete urlData[key];
            }
        });
    });


    queryString = _stringify(urlData);

    if (queryString) {
        queryString = `?${queryString}`;
    }

    if (window.location.search !== queryString) {
        _replaceState(`${window.location.pathname}${queryString}${window.location.hash}`);
        _callUrlChangeHandlers();
    }
}

function getUrlData() {
    return {
        ..._parseQueryString(window.location.hash.slice(1)),
        ..._parseQueryString(window.location.search.slice(1)),
    };
}

function _callUrlChangeHandlers() {
    const urlData = getUrlData();
    urlChangeHandlers.forEach(cb => cb(urlData));
}

function onUrlChange(fn) {
    if (!urlChangeHandlers.length) {
        currentPathname = window.location.pathname;

        _on(window, 'popstate', () => {
            if (currentPathname === window.location.pathname) {
                _callUrlChangeHandlers();
            }
        });
    }

    urlChangeHandlers.push(fn);
}

export {
    setRootElement,
    updateUrl,
    getUrlData,
    onUrlChange,
    isMobile,
};
