import { _createEl } from '../../common/_create-el';
import './__button-right.scss';

const blockName = 'layout-search';

export function layoutSearchButtonRight() {
    return _createEl('div', `${blockName}__icon ${blockName}__icon_type_right`, [
        _createEl('i', 'fa fa-angle-right'),
    ]);
}
