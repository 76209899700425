import { _EventEmitter } from '../../common/_events';
import StoreInterface from './__store';

const STATE_INACTIVE = 'inactive';
const STATE_LOADING_ERROR = 'loading_error';
const STATE_LOADING = 'loading';
const STATE_LOADED = 'loaded';

const URL_DATA_KEY = 'shops';

const OffersFiltersPanelStoreShops = {
    _state: {
        selectedShops: [],
    },

    _searchShops: {
        state: STATE_INACTIVE,
        list: [],
    },

    _categorizedShops: {
        state: STATE_LOADED,
        list: [],
    },

    selectShop(shopId, shopName) {
        if (!this.isShopSelected(shopId)) {
            this._state.selectedShops.push({
                id: String(shopId),
                name: String(shopName),
            });

            this.eventEmitter.emit('change');
        }
    },

    unSelectShop(rawShopId) {
        const shopId = String(rawShopId);

        if (this.isShopSelected(shopId)) {
            this._state.selectedShops = this._state.selectedShops
                .filter(shop => shop.id !== shopId);

            this.eventEmitter.emit('change');
        }
    },

    isShopSelected(rawShopId) {
        const shopId = String(rawShopId);

        return this._state.selectedShops.some(shop => shop.id === shopId);
    },

    getSelectedShops() {
        return this._state.selectedShops.map(shop => ({
            id: shop.id,
            name: shop.name,
            isSelected: true,
        }));
    },

    isSearchSectionActive() {
        return this._searchShops.state === STATE_LOADING || this._searchShops.state === STATE_LOADED;
    },

    isSearchSectionLoading() {
        return this._searchShops.state === STATE_LOADING;
    },

    isSearchSectionEmpty() {
        return this._searchShops.state === STATE_LOADED && this._searchShops.list.length < 1;
    },

    markSearchAsInactive() {
        this._searchShops.state = STATE_INACTIVE;
        this.eventEmitter.emit('change');
    },

    markSearchAsLoading() {
        this._searchShops.state = STATE_LOADING;
        this._searchShops.list = [];
        this.eventEmitter.emit('change');
    },

    getSearchedShops() {
        return this._searchShops.list.map(shop => ({
            id: shop.id,
            name: shop.name,
            nameWithQuery: shop.nameWithQuery,
            isSelected: this.isShopSelected(shop.id),
        }));
    },

    setSearchedShops(list) {
        this._searchShops.state = STATE_LOADED;
        this._searchShops.list = list.map(shop => ({
            id: String(shop.id),
            name: String(shop.name),
            nameWithQuery: String(shop.nameWithQuery),
        }));

        this.eventEmitter.emit('change');
    },

    markCategorizedAsLoading() {
        this._categorizedShops.state = STATE_LOADING;
        this._categorizedShops.list = [];

        this.eventEmitter.emit('change');
    },

    markCategorizedAsLoadingError() {
        this._categorizedShops.state = STATE_LOADING_ERROR;
        this._categorizedShops.list = [];

        this.eventEmitter.emit('change');
    },

    isCategorizedSectionEmpty() {
        return this._categorizedShops.state === STATE_LOADED && this._categorizedShops.list.length === 0;
    },

    isCategorizedSectionLoading() {
        return this._categorizedShops.state === STATE_LOADING;
    },

    getCategorizedShops() {
        return this._categorizedShops.list.map(shop => ({
            id: shop.id,
            name: shop.name,
            isSelected: this.isShopSelected(shop.id),
        }));
    },

    setCategorizedShops(list) {
        this._categorizedShops.state = STATE_LOADED;
        this._categorizedShops.list = list.map(shop => ({
            id: String(shop.id),
            name: String(shop.name),
        }));

        this.eventEmitter.emit('change');
    },

    getValidUrlDataKeys() {
        return [URL_DATA_KEY];
    },

    fromUrlData(data) {
        if (this.isInited()) {
            const currentState = JSON.stringify(this._state);
            const originalEventEmitter = this.eventEmitter;

            this.eventEmitter = new _EventEmitter();
            this.toDefaultState();

            if (data[URL_DATA_KEY]) {
                const cache = this._namesCache || this._initialNamesCache || {};
                const shops = Array.isArray(data[URL_DATA_KEY])
                    ? data[URL_DATA_KEY].slice()
                    : [data[URL_DATA_KEY]];

                shops.forEach((shopId) => {
                    if (cache[shopId]) {
                        this.selectShop(shopId, cache[shopId]);
                    }
                });
            }

            this.eventEmitter = originalEventEmitter;
            delete this._initialNamesCache;

            if (JSON.stringify(this._state) !== currentState) {
                originalEventEmitter.emit('change');
            }
        }
    },

    getUrlData() {
        if (this.isInDefaultState()) {
            return {};
        }

        this._namesCache = {};

        return {
            [URL_DATA_KEY]: this._state.selectedShops.map((shop) => {
                this._namesCache[shop.id] = shop.name;
                return shop.id;
            }),
        };
    },

    getRequestData() {
        return {
            [URL_DATA_KEY]: this._state.selectedShops.map(shop => shop.id),
        };
    },

    getTags() {
        return this._state.selectedShops.map(shop => ({
            id: shop.id,
            label: shop.name,
        }));
    },

    init({ categorizedShops, initialCache }) {
        if (!this.isInited()) {
            this._categorizedShops.list = categorizedShops.map(shop => ({
                id: String(shop.id),
                name: String(shop.name),
            }));

            this._initialNamesCache = initialCache;
            StoreInterface.init(this);
        }
    },
};

export default StoreInterface.extend(OffersFiltersPanelStoreShops);
