import bugsnag from '../../common/_bugsnag-client';
import components from '../../common/_components';
import { _on } from '../../common/_events';
import { _getData } from '../../common/_core';
import { _createEl } from '../../common/_create-el';
import { Search, SearchSection, SearchStates, searchHighlightQuery } from '../../common/_search';
import ShopsStore from './__store-shops';
import backTemplate from './__back-el';
import searchTemplate from './__shopping-search-el';
import shopsTemplate from './__shopping-shops-el';

const minLength = 2;

const blockName = 'offers-filters-panel';
const shadowClass = `${blockName}__with-shadow`;

class OffersFiltersPanelShopping {
    static instance = null;

    static options = null;

    static setOptions = ({ shopsInputPlaceholder, shopsNoResults, shopsSearchUrl }) => {
        OffersFiltersPanelShopping.options = {
            noResultsLabel: shopsNoResults,
            searchInputPlaceholder: shopsInputPlaceholder,
            searchShopsUrl: shopsSearchUrl,
        };
    };

    static resetInput = () => {
        const createdInstance = OffersFiltersPanelShopping.instance;

        if (createdInstance) {
            createdInstance.emptyInput();
        }
    };

    static updateShadow = () => {
        const createdInstance = OffersFiltersPanelShopping.instance;

        if (createdInstance) {
            createdInstance.updateShadow();
        }
    };

    constructor(rootEl) {
        if (this.constructor.instance) {
            return this.constructor.instance;
        }

        if (!this.constructor.options) {
            bugsnag.notify('OffersFiltersPanelShopping.setOptions should be called before constructor');
        }

        const searchEl = searchTemplate({
            inputPlaceholder: this.constructor.options.searchInputPlaceholder,
        });

        this._rootEl = rootEl;
        this._inputEl = searchEl.querySelector(`.js-${blockName}__search-input`);
        this._shopsEl = _createEl('div', 'offers-filters-panel__shops');

        this._render();
        this._search = this._getSearch();

        ShopsStore.eventEmitter.subscribe('change', this._render);
        _on(this._rootEl, 'scroll', this.updateShadow);

        this.constructor.instance = this;

        this._rootEl.appendChild(backTemplate({ header: _getData(rootEl, 'header') }));
        this._rootEl.appendChild(searchEl);
        this._rootEl.appendChild(this._shopsEl);
    }

    emptyInput = () => {
        this._search.setValue('');
    };

    updateShadow = () => {
        const bottomPosition = this._rootEl.getBoundingClientRect().height + this._rootEl.scrollTop;
        const needsShadow = (bottomPosition !== this._rootEl.scrollHeight);

        this._rootEl.classList[needsShadow ? 'add' : 'remove'](shadowClass);
    };

    _render = () => {
        const isSearchSectionActive = ShopsStore.isSearchSectionActive();
        const isSearchSectionEmpty = ShopsStore.isSearchSectionEmpty();
        const isSearchSectionLoading = ShopsStore.isSearchSectionLoading();
        const isCategorizedSectionLoading = ShopsStore.isCategorizedSectionLoading();

        const getShops = () => {
            if (isSearchSectionActive) {
                return isSearchSectionLoading ? [] : ShopsStore.getSearchedShops();
            }

            return isCategorizedSectionLoading ? [] : ShopsStore.getCategorizedShops();
        };

        this._shopsEl.innerHTML = '';
        this._shopsEl.appendChild(shopsTemplate({
            isLoading: isSearchSectionActive ? isSearchSectionLoading : isCategorizedSectionLoading,
            searchResultsEmptyLabel: isSearchSectionActive && isSearchSectionEmpty
                && this.constructor.options.noResultsLabel,
            shops: getShops(),
        }));

        this.updateShadow();
    };

    _getSearch = () => new Search({
        inputEl: this._inputEl,
        onValueChange: this._onValueChange,
        sections: new SearchSection({
            url: `${this.constructor.options.searchShopsUrl}?search={{query}}`,
            wildcard: '{{query}}',
            render: (state, query, suggestions) => {
                if (state === SearchStates.PENDING) {
                    ShopsStore.markSearchAsLoading();
                } else if (state === SearchStates.NOT_FOUND) {
                    ShopsStore.setSearchedShops([]);
                } else {
                    ShopsStore.setSearchedShops(suggestions.map(shop => ({
                        id: shop.id,
                        name: shop.name,
                        nameWithQuery: window.offersFiltersPanelShopsHighlight === false
                            ? shop.name
                            : searchHighlightQuery(query, shop.name),
                    })));
                }
            },
        }),
    });

    _onValueChange = () => {
        if (Search.processQuery(this._inputEl.value).length < minLength) {
            ShopsStore.markSearchAsInactive();
        }
    };
}

components.add(`js-${blockName}__shopping`, el => new OffersFiltersPanelShopping(el));

export default OffersFiltersPanelShopping;
