import { _createEl } from '../../common/_create-el';

export default function(data) {
    const fragment = document.createDocumentFragment();
    const titleID = 'offers-filters-panel-details-meta-title';
    const sliderValueTextClass = 'offers-filters-panel__slider-value-text';

    fragment.appendChild(_createEl('div', 'offers-filters-panel__filters-list js-offers-filters-panel__filters-list'));
    fragment.appendChild(_createEl('div', 'offers-filters-panel__details-meta', [
        _createEl('div', 'offers-filters-panel__details-meta-title', { id: titleID }, [data.sliderHeader]),
        _createEl('div', 'offers-filters-panel__slider', [
            _createEl('input', 'offers-filters-panel__slider-input js-offers-filters-panel__slider-input', {
                'min': data.sliderMinValue,
                'max': data.sliderMaxValue,
                'value': data.sliderDefaultValue,
                'type': 'range',
                'step': 5,
                'aria-labelledby': titleID,
            }),
            _createEl('div', 'offers-filters-panel__slider-min', [`${data.sliderMinValue}${data.sliderCurrency}`]),
            _createEl('div', 'offers-filters-panel__slider-value', [
                _createEl('span', 'offers-filters-panel__slider-value-placeholder', [data.sliderMaxValueLabel]),
                _createEl('span', 'offers-filters-panel__slider-value-placeholder', [
                    `${data.sliderMaxValue}${data.sliderCurrency}`,
                ]),
                _createEl('span', `${sliderValueTextClass} js-${sliderValueTextClass}`, [
                    data.sliderMaxValueLabel,
                ]),
            ]),
        ]),
    ]));

    return fragment;
}
