import components from '../../common/_components';
import { _toArray } from '../../common/_core';
import { _on } from '../../common/_events';
import ScrollHorizontal from '../../common/_scroll-horizontal';

import './carousel.scss';

const interval = 3000;
const animationDuration = 600;
const blockName = 'carousel';
const indicatorClassName = `${blockName}__indicator`;
const indicatorAttrName = 'slideId';

class Carousel {
    constructor(rootEl) {
        this._rootEl = rootEl;
        this._indicatorsEl = rootEl.querySelector(`.js-${blockName}__indicators-list`);
        this._isMouseOver = false;

        const slides = _toArray(this._rootEl.querySelectorAll(`.js-${blockName}__slide`));

        if (slides.length > 1) {
            this._scroll = new ScrollHorizontal(rootEl, `.js-${blockName}__slide`, {
                scrollEl: rootEl.querySelector(`.js-${blockName}__slides`),
                isInfinity: true,
                onDragStart: this._stopCarousel,
                onSlideChange: this._slideChangeHandler,
                onSlideChangeDone: this._slideChangeDoneHandler,
            });

            _on(this._rootEl, 'mouseover', this._mouseOverHandler);
            _on(this._rootEl, 'mouseout', this._mouseOutHandler);

            _toArray(this._rootEl.querySelectorAll(`.js-${blockName}__navigation-button`)).forEach((buttonEl) => {
                buttonEl.classList.add('active');

                _on(buttonEl, 'click', this._onNavigationClick);
                _on(buttonEl, 'dblclick', this._onNavigationClick);
            });

            _on(this._indicatorsEl, 'click', `.${indicatorClassName}`, this._onIndicatorClick);

            slides.forEach((slide) => {
                const span = document.createElement('span');

                span.className = indicatorClassName;
                span.setAttribute(indicatorAttrName, ScrollHorizontal.getSlideId(slide));

                this._indicatorsEl.appendChild(span);
            });

            this._slideChangeHandler(this._scroll.getCurrentSlideId());
            this._startCarousel();
        }
    }

    _mouseOverHandler = (e) => {
        const rootEl = this._rootEl;
        const relatedTargetEl = e.relatedTarget;

        if (!relatedTargetEl || (relatedTargetEl !== rootEl && !rootEl.contains(relatedTargetEl))) {
            this._isMouseOver = true;
            this._stopCarousel();
        }
    };

    _mouseOutHandler = (e) => {
        const rootEl = this._rootEl;
        const relatedTargetEl = e.relatedTarget;

        if (!relatedTargetEl || (relatedTargetEl !== rootEl && !rootEl.contains(relatedTargetEl))) {
            this._isMouseOver = false;
            this._startCarousel();
        }
    };

    _slideChangeHandler = (newSlideId) => {
        _toArray(this._indicatorsEl.querySelectorAll(`.${indicatorClassName}`)).forEach((indicatorEl) => {
            const isActive = (indicatorEl.getAttribute(indicatorAttrName) === newSlideId);
            indicatorEl.classList[isActive ? 'add' : 'remove']('active');
        });
    };

    _slideChangeDoneHandler = () => {
        if (!this._isMouseOver) {
            this._startCarousel();
        }
    };

    _onNavigationClick = (e) => {
        const buttonEl = e.currentTarget;

        if (buttonEl.classList.contains(`${blockName}__navigation-button_type_prev`)) {
            this._scroll.moveLeft(animationDuration);
        } else if (buttonEl.classList.contains(`${blockName}__navigation-button_type_next`)) {
            this._scroll.moveRight(animationDuration);
        }

        return false;
    };

    _onIndicatorClick = (e) => {
        this._scroll.moveTo(e._caughtTarget_.getAttribute(indicatorAttrName), animationDuration);
    };

    _startCarousel = () => {
        this._stopCarousel();
        this._timeout = setTimeout(() => {
            this._scroll.moveRight(animationDuration);
        }, interval);
    };

    _stopCarousel = () => {
        clearTimeout(this._timeout);
    }
}

components.add(`js-${blockName}`, element => new Carousel(element));
