import { _EventEmitter } from '../../common/_events';

const OffersFiltersPanelStoreInterface = {
    init() {
        const initialState = JSON.stringify(this._state);

        this.eventEmitter = new _EventEmitter();
        this._initialState = initialState;
        this._previousState = initialState;
    },

    isInited() {
        return Boolean(this._initialState);
    },

    isInDefaultState() {
        return (this._initialState === JSON.stringify(this._state));
    },

    toDefaultState() {
        if (!this.isInDefaultState()) {
            this._state = JSON.parse(this._initialState);
            this.eventEmitter.emit('change');
        }
    },

    hasChanges() {
        return (this._previousState !== JSON.stringify(this._state));
    },

    applyChanges() {
        if (this.hasChanges()) {
            this._previousState = JSON.stringify(this._state);
            this.eventEmitter.emit('change');
            this.eventEmitter.emit('apply');
        }
    },

    resetChanges() {
        if (this.hasChanges()) {
            this._state = JSON.parse(this._previousState);
            this.eventEmitter.emit('change');
        }
    },
};

export default {
    extend: (store) => {
        Object.keys(OffersFiltersPanelStoreInterface).forEach((method) => {
            const shouldBeCopied = !Object.prototype.hasOwnProperty.call(store, method);
            const isMethod = (typeof OffersFiltersPanelStoreInterface[method] === 'function');

            if (shouldBeCopied && isMethod) {
                // eslint-disable-next-line no-param-reassign
                store[method] = OffersFiltersPanelStoreInterface[method];
            }
        });

        return store;
    },
    init: store => OffersFiltersPanelStoreInterface.init.call(store),
};
