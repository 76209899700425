import components from '../../common/_components';
import { _on, _onTransitionEnd } from '../../common/_events';
import { _getData } from '../../common/_core';
import { _createEl } from '../../common/_create-el';
import Cookies from '../../vendors/_cookies';
import './cookie-notice.scss';

const AGREEMENT_COOKIE_NAME = 'COOKIE_NOTICE';
const AGREEMENT_COOKIE_SHOWN = 'true';

const blockName = 'cookie-notice';
const hiddenMod = `${blockName}_hidden`;
const willChangeMod = `${blockName}_will-change`;

class CookieNotice {
    constructor(rootEl) {
        this._rootEl = rootEl;

        this._display();
    }

    _display = () => {
        const imageUrl = _getData(this._rootEl, 'imageUrl');
        const text = _getData(this._rootEl, 'notice');
        const closeIconHTML = `<svg class="${blockName}__close-img" width="12" height="12" viewBox="0 0 12 12"
xmlns="http://www.w3.org/2000/svg">
<path d="M6.75 6L12 .75 11.25 0 6 5.25.75 0 0 .75 5.25 6 0 11.25l.75.75L6 6.75 11.25 12l.75-.75z" fill-rule="evenodd">
</path></svg>`;
        const closeButtonEl = _createEl('div', `${blockName}__close`, {
            'role': 'button',
            'aria-label': 'Close',
        });
        const textEl = _createEl('div', 'cookie-notice__text', { id: 'cookie-notice-text' });
        const innerEl = _createEl('div', 'cookie-notice__inner', {
            'role': 'alertdialog',
            'aria-describedby': 'cookie-notice-text',
        }, [
            _createEl('img', 'cookie-notice__img', {
                src: imageUrl,
                width: 52,
                height: 64,
                alt: '',
            }),
        ]);

        innerEl.appendChild(textEl);
        textEl.innerHTML = text;
        closeButtonEl.innerHTML = closeIconHTML;

        _on(closeButtonEl, 'mouseover', this._onMouseOver);
        _on(closeButtonEl, 'click', this._onCloseClick);

        this._rootEl.appendChild(closeButtonEl);
        this._rootEl.appendChild(innerEl);
    };

    _onCloseClick = () => {
        Cookies.set(AGREEMENT_COOKIE_NAME, AGREEMENT_COOKIE_SHOWN, {
            expires: 365,
            path: '/',
        });

        _onTransitionEnd(this._rootEl, () => {
            const rootClassList = this._rootEl.classList;

            if (rootClassList.contains(hiddenMod)) {
                this._rootEl.innerHTML = '';
                rootClassList.remove(hiddenMod);
                rootClassList.remove(willChangeMod);
            }
        });

        this._rootEl.classList.add(hiddenMod);
    };

    _onMouseOver = () => {
        this._rootEl.classList.add(willChangeMod);
    };
}

components.add(`js-${blockName}`, el => new CookieNotice(el));
