/*
 * WARNING!
 * Content of this file was for automatically generated (with `./bin/build-entries.js`).
 * All changes will be replaced.
 */
/* Libraries */
import './vendors/_bootstrap.scss';
import './vendors/_font-awesome.scss';

import './vendors/_lazysizes';
import './vendors/_picturefill';

/* Common components */
import './components/stat/stat.js';
import './components/clickout/clickout.js';
import './components/shadow-box/shadow-box.scss';
import './components/buttons/buttons.scss';
import './components/cookie-notice/cookie-notice.js';


/* Layout */
import './components/layout-search/layout-search.js';

import './components/layout-header-desktop/layout-header-desktop.js';
import './components/layout-header-mobile/layout-header-mobile.js';
import './components/layout-menu/layout-menu.js';
import './components/layout-menu-shops/layout-menu-shops.scss';
import './components/layout-menu-categories/layout-menu-categories.scss';
import './components/layout-menu-tiles/layout-menu-tiles.scss';
import './components/layout-menu-footer/layout-menu-footer.scss';
import './components/layout-breadcrumb/layout-breadcrumb.scss';

import './components/layout-footer/layout-footer.scss';




import './components/page-header/page-header.scss';
import './components/page-intro/page-intro.scss';
import './components/page-list/page-list.scss';
import './components/page-pagination/page-pagination.scss';
import './components/flash-message/flash-message.scss';

/* Pages */
import './components/page-shop-categories-index/page-shop-categories-index.scss';
import './components/page-shop-categories-view/page-shop-categories-view.scss';
import './components/page-shops-index/page-shops-index.js';
import './components/page-shops-view/page-shops-view.js';




import './components/page-shop-pages-view/page-shop-pages-view.scss';
import './components/page-sponsored-pages-view/page-sponsored-pages-view.scss';
import './components/page-landing-pages-view/page-landing-pages-view.scss';
import './components/page-landing-pages-index/page-landing-pages-index.scss';
import './components/page-dashboard-home/page-dashboard-home.scss';
import './components/page-pages-view/page-pages-view.scss';
import './components/page-posts-view/page-posts-view.scss';
import './components/page-post-categories-view/page-post-categories-view.scss';
import './components/page-posts-index/page-posts-index.scss';
import './../../Tanio/assets/components/page-error/page-error.scss';
import './components/page-offers-index/page-offers-index.js';
import './components/page-offers-view/page-offers-view.js';
import './components/page-offers-search/page-offers-search.scss';
import './components/page-calculators-index/page-calculators-index.scss';
import './components/page-calculators-view/page-calculators-view.scss';







/* Components */
import './components/badge/badge.scss';

import './components/tooltip/tooltip.js';
import './components/lightbox/lightbox.js';

import './components/show-all-link/show-all-link.scss';
import './components/posts-meta/posts-meta.scss';
import './components/posts-share/posts-share.js';
import './components/posts-list-item-primary/posts-list-item-primary.scss';
import './components/offers-voting/offers-voting.js';
import './components/offers-label/offers-label.scss';
import './components/offers-label/_type_primary/offers-label_type_primary.scss';

import './components/offers-list-item-primary/offers-list-item-primary.scss';

import './components/offers-list-item-tertiary/offers-list-item-tertiary.scss';
import './components/offers-list-item-quinary/offers-list-item-quinary.scss';
import './components/offers-list-primary/offers-list-primary.scss';
import './components/offers-list-tertiary/offers-list-tertiary.scss';
import './components/offers-list-quinary/offers-list-quinary.scss';
import './components/shops-list-item-primary/shops-list-item-primary.scss';
import './components/shops-list-item-secondary/shops-list-item-secondary.scss';
import './components/shops-list-item-tertiary/shops-list-item-tertiary.scss';
import './components/shops-list-primary/shops-list-primary.scss';
import './components/shops-list-tertiary/shops-list-tertiary.scss';
import './components/shop-categories-list-item-primary/shop-categories-list-item-primary.scss';
import './components/shop-categories-list-item-secondary/shop-categories-list-item-secondary.scss';
import './components/shop-categories-list-secondary/shop-categories-list-secondary.scss';
import './components/carousel/carousel.js';
import './components/shops-logo-carousel/shops-logo-carousel.js';
import './components/rating/rating.js';
import './components/wysiwyg-content/wysiwyg-content.scss';
import './components/wysiwyg-accordion/wysiwyg-accordion.js';
import './components/wysiwyg-text-box/wysiwyg-text-box.scss';


import './components/layout-description/layout-description.js';
import './components/landing-pages-list-item-primary/landing-pages-list-item-primary.scss';
import './components/image-grayscale/image-grayscale.scss';
import './components/load-more/load-more.js';
import './components/load-more-page/load-more-page.js';
import './components/teaser/teaser.scss';
import './components/teaser-content-post/teaser-content-post.scss';

import './components/teaser-content-calculators-view/teaser-content-calculators-view.scss';
import './components/teaser-content-landing-page/teaser-content-landing-page.js';
import './components/shops-description-headlines/shops-description-headlines.js';
import './components/top-categories/top-categories.scss';
import './../../Tanio/assets/components/newsletter-primary/newsletter-primary.js';
import './components/calculators-similar/calculators-similar.scss';
import './components/calculators-list-item-primary/calculators-list-item-primary.scss';
import './components/calculator-category-list/calculator-category-list.scss';
import './components/no-js-notification/no-js-notification.scss';




import './components/deals-list-item-primary/deals-list-item-primary.scss';






import './components/shops-card/shops-card.scss';
import './components/shops-filters-panel/shops-filters-panel.js';
import './components/shops-offers-list/shops-offers-list.js';
import './components/shops-similar/shops-similar.scss';
import './components/shops-top-offers/shops-top-offers.scss';
import './components/shops-top-offers-table/shops-top-offers-table.scss';
import './components/shops-footer/shops-footer.scss';
import './components/shops-topic-tile/shops-topic-tile.scss';

import './components/range-slider/range-slider.js';
import './components/landing-pages-homepage/landing-pages-homepage.scss';
import './components/offers-filters-panel/offers-filters-panel.js';
import './components/offers-filters-headline/offers-filters-headline.js';
import './components/offers-columns/offers-columns.scss';
import './components/scroll-up-button/scroll-up-button.js';
import './components/offers-statistics/offers-statistics.scss';
import './components/post-categories-tabber/post-categories-tabber.js';
import './components/versus-widget/versus-widget.js';
import './components/top-shops-list/top-shops-list.scss';
























