import { _createEl } from '../../common/_create-el';
import './__button-search.scss';

const blockName = 'layout-search';

export function layoutSearchButtonSearch() {
    return _createEl('div', `${blockName}__icon ${blockName}__icon_type_search`, [
        _createEl('i', 'fa fa-search'),
    ]);
}
