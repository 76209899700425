import components from '../../common/_components';
import { _on } from '../../common/_events';
import { _getData } from '../../common/_core';
import { _copyText } from '../../common/_selection';
import Tooltip from '../tooltip/tooltip';
import './posts-share.scss';

const blockName = 'posts-share';
const linkClass = `${blockName}__link`;
const clipboardLinkClass = `${blockName}__clipboard-link`;

components.add(`js-${blockName}`, (element) => {
    const clipboardLinkEl = element.querySelector(`.js-${clipboardLinkClass}`);

    let hideTooltipTimeout;

    _on(clipboardLinkEl, 'click', () => {
        _copyText(_getData(clipboardLinkEl, 'clipboardText'), {
            onSuccess: () => {
                const tooltip = new Tooltip(clipboardLinkEl);

                if (hideTooltipTimeout) {
                    clearTimeout(hideTooltipTimeout);
                }

                tooltip.show(_getData(clipboardLinkEl, 'successText'));
                hideTooltipTimeout = setTimeout(tooltip.hide, 3000);
            },
        });
    });

    _on(element, 'click', `.js-${linkClass}`, (e) => {
        const targetEl = e._caughtTarget_;
        const width = _getData(targetEl, 'popupWidth') || '600';
        const height = _getData(targetEl, 'popupHeight') || '500';

        window.open(targetEl.getAttribute('href'), 'share', `top=100,left=100,width=${width},height=${height}`);
        e.preventDefault();
    });
});
