import { _createEl } from '../../common/_create-el';
import filterTemplate from './__filter-el';

export default (data) => {
    const content = [];

    if (data.isLoading) {
        content.push(_createEl('div', 'offers-filters-panel__loading', [
            _createEl('i', 'fa fa-spin fa-circle-notch offers-filters-panel__loading-spinner'),
        ]));
    } else if (data.searchResultsEmptyLabel) {
        content.push(_createEl('div', 'offers-filters-panel__no-results-text', [data.searchResultsEmptyLabel]));
    } else {
        data.shops.forEach(shopData => content.push(filterTemplate({
            isSelected: shopData.isSelected,
            id: shopData.id,
            name: shopData.name,
            nameWithQuery: shopData.nameWithQuery,
        })));
    }

    return _createEl('div', 'offers-filters-panel__filters-list', content);
};
