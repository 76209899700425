import components from '../../common/_components';
import { _on } from '../../common/_events';
import ScrollHorizontal from '../../common/_scroll-horizontal';

import './shops-logo-carousel.scss';

const interval = 3500;
const blockName = 'shops-logo-carousel';

class ShopsLogoCarousel {
    constructor(rootEl) {
        this._rootEl = rootEl;
        this._isContextMenuOpened = false;

        this._scroll = new ScrollHorizontal(rootEl, `.js-${blockName}__item`, {
            listEl: rootEl.querySelector(`.js-${blockName}__list`),
            isInfinity: true,
            onDragStart: this.dragStartHandler,
            onSlideChangeDone: this.slideChangeDoneHandler,
        });

        if (rootEl.getBoundingClientRect().width < rootEl.scrollWidth) {
            _on(this._rootEl, 'contextmenu', this.contextMenuHandler);
            this._timeout = setTimeout(this.scrollLeft, interval);
        }
    }

    dragStartHandler = () => {
        clearTimeout(this._timeout);
    };

    slideChangeDoneHandler = () => {
        if (!this._isContextMenuOpened) {
            this._timeout = setTimeout(this.scrollLeft, interval);
        }
    };

    contextMenuHandler = () => {
        clearTimeout(this._timeout);
        this._scroll.stopAnimation();

        this._isContextMenuOpened = true;
    };

    scrollLeft = () => {
        this._scroll.moveRight();
    };
}

components.add(`js-${blockName}`, el => new ShopsLogoCarousel(el));
