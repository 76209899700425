import components from '../../common/_components';
import HeadersHeightManager from '../../common/_headers-height-manager';
import StickyElement from '../../common/_sticky-element';
import { _on, _off, _onTransitionEnd, _trigger } from '../../common/_events';
import { _closest, _getCSSProp, _getData, _reflow, _removeData, _setData, _toArray } from '../../common/_core';
import { lockPageScrolling, unlockPageScrolling } from '../../common/_scroll-page-lock';
import LayoutSearch from '../layout-search/layout-search';
import { layoutSearchButtonSearch } from '../layout-search/__button-search';
import { layoutSearchButtonClose } from '../layout-search/__button-close';
import { layoutSearchInput } from '../layout-search/__input';
import { layoutSearchButtonRight } from '../layout-search/__button-right';
import { LayoutSearchResultsMarkupFactory } from '../layout-search/__results-markup-factory';
import { layoutSearchResultsSection } from '../layout-search/__results-section';
import { layoutSearchResultsSuggestionOffer } from '../layout-search/__results-suggestion-offer';
import { layoutSearchResultsSuggestionShop } from '../layout-search/__results-suggestion-shop';
import Overlay from '../overlay/overlay';

import './layout-menu.scss';

const blockName = 'layout-menu';
const openedMod = `${blockName}_opened`;

const navigationItemSelector = `.js-${blockName}__navigation-li`;
const navigationItemExpandedMod = `${blockName}__navigation-li_expanded`;
const navigationItemAnimatingMod = `${blockName}__navigation-li_animating`;
const navigationLinkSelector = `.js-${blockName}__navigation-link`;

class LayoutMenu {
    constructor(rootEl) {
        this._rootEl = rootEl;
        this._fixedEl = rootEl.querySelector(`.js-${blockName}__fixed`);
        this._bodyEl = rootEl.querySelector(`.js-${blockName}__body`);
        this._mobileWidthLimit = parseFloat(_getData(rootEl, 'mobileWidthLimit'));

        this._overlay = new Overlay({ forMobileOnly: true });
        this._updateHandlers();

        HeadersHeightManager.addStateChangeHandler(this._headerChangeHandler);
        HeadersHeightManager.setupHeader(this._fixedEl, this._mobileWidthLimit, Infinity);
    }

    _headerChangeHandler = (activePanel, state) => {
        if (!activePanel || activePanel.rootEl !== this._fixedEl) {
            return;
        }

        if (state === HeadersHeightManager.STATE_ACTIVATED && !this._stickyElement) {
            this._collapseNavigationItems();
            this._closeMenu();
            this._updateHandlers();

            this._stickyElement = new StickyElement(this._fixedEl, this._rootEl, {
                pushWithHeader: false,
                beforeStateChange: () => {},
                afterStateChange: this._afterStickyStateChange,
            });
        } else if (state === HeadersHeightManager.STATE_DEACTIVATED && this._stickyElement) {
            this._stickyElement.destroy();
            this._stickyElement = null;

            this._updateHandlers();

            _trigger(document, 'layout-menu__active-search-change', {
                placement: LayoutSearch.PLACEMENT_HEADER_MOBILE,
            });
        }
    };

    _updateHandlers = () => {
        const HANDLERS_MODE_DESKTOP = 'desktop';
        const HANDLERS_MODE_MOBILE = 'mobile';

        const prevHandlersMode = this._handlersMode;
        const handlersMode = document.body.clientWidth < this._mobileWidthLimit
            ? HANDLERS_MODE_MOBILE : HANDLERS_MODE_DESKTOP;

        if (prevHandlersMode !== handlersMode) {
            if (prevHandlersMode === HANDLERS_MODE_DESKTOP) {
                _off(this._rootEl, 'pointerover', navigationLinkSelector, this._linkMouseoverHandler);
                _off(document, 'layout-menu__active-search-change', this._activeSearchChangeHandler);
            } else if (prevHandlersMode === HANDLERS_MODE_MOBILE) {
                _off(document, 'menu-mobile_opened', this._openMenu);
                _off(document, this._overlay.getCloseEvent(), this._closeMenu);

                _off(this._rootEl, 'click', `.js-${blockName}__heading`, this._headingClickHandler);
                _off(this._rootEl, 'click', `.js-${blockName}__navigation-aside`, this._asideArrowTouchendHandler);
            }

            if (handlersMode === HANDLERS_MODE_DESKTOP) {
                _on(this._rootEl, 'pointerover', navigationLinkSelector, this._linkMouseoverHandler);
                _on(document, 'layout-menu__active-search-change', this._activeSearchChangeHandler);
            } else if (handlersMode === HANDLERS_MODE_MOBILE) {
                _on(document, 'menu-mobile_opened', this._openMenu);
                _on(document, this._overlay.getCloseEvent(), this._closeMenu);

                _on(this._rootEl, 'click', `.js-${blockName}__heading`, this._headingClickHandler);
                _on(this._rootEl, 'click', `.js-${blockName}__navigation-aside`,
                    this._asideArrowTouchendHandler, { passive: true });
            }

            this._handlersMode = handlersMode;
        }
    };

    _afterStickyStateChange = (state) => {
        const animatingMod = `${blockName}_expandable-animation`;
        const expandedMod = `${blockName}_expanded`;
        const isExpanded = this._rootEl.classList.contains(expandedMod);
        const shouldBeExpanded = (state === StickyElement.STATE_FIXED || state === StickyElement.STATE_ABSOLUTE);
        const handleTransitionEnd = (el) => {
            if (_getData(el, 'transitionEndHandler') !== 'true') {
                _onTransitionEnd(el, () => {
                    this._rootEl.classList.remove(animatingMod);
                    el.style.width = '';

                    _removeData(el, 'transitionEndHandler');
                });

                _setData(el, 'transitionEndHandler', 'true');
            }
        };

        if (shouldBeExpanded && !isExpanded) {
            const listEl = this._bodyEl.querySelector(`.js-${blockName}__navigation-ul`);

            this._bodyEl.style.width = `${listEl.offsetWidth}px`;

            this._rootEl.classList.add(animatingMod);
            this._rootEl.classList.add(expandedMod);

            handleTransitionEnd(this._bodyEl);
        } else if (!shouldBeExpanded && isExpanded) {
            this._bodyEl.style.width = `${this._bodyEl.offsetWidth}px`;

            this._rootEl.classList.add(animatingMod);
            this._rootEl.classList.remove(expandedMod);

            _reflow(this._bodyEl);
            this._bodyEl.style.width = '';

            handleTransitionEnd(this._bodyEl);
        }

        _trigger(document, 'layout-menu__active-search-change', {
            placement: state === StickyElement.STATE_DEFAULT
                ? LayoutSearch.PLACEMENT_HEADER_DESKTOP
                : LayoutSearch.PLACEMENT_HEADER_MENU,
        });
    };

    _activeSearchChangeHandler = (e) => {
        const data = e.detail || {};

        if (data.placement === LayoutSearch.PLACEMENT_HEADER_MENU) {
            if (!this._layoutSearch) {
                this._layoutSearch = new LayoutSearch(this._rootEl.querySelector(`.js-${blockName}__search`), {
                    formElements: [
                        { role: LayoutSearch.ELEMENT_ROLE_SUBMIT, getEl: layoutSearchButtonSearch },
                        { role: LayoutSearch.ELEMENT_ROLE_RESET, getEl: layoutSearchButtonClose },
                        { role: LayoutSearch.ELEMENT_ROLE_INPUT, getEl: layoutSearchInput },
                        { role: LayoutSearch.ELEMENT_ROLE_SUBMIT, getEl: layoutSearchButtonRight },
                    ],
                    resultsMarkupFactory: new LayoutSearchResultsMarkupFactory({
                        className: `${blockName}__search-results`,
                        section: layoutSearchResultsSection,
                        suggestions: {
                            'offers': layoutSearchResultsSuggestionOffer,
                            'shops': layoutSearchResultsSuggestionShop,
                        },
                    }),
                    placement: LayoutSearch.PLACEMENT_HEADER_MENU,
                    queriesPromotion: true,
                    onReset: (type) => {
                        if (type === LayoutSearch.RESET_TYPE_BUTTON_CLICK) {
                            this._layoutSearch.focusInput();
                        }
                    },
                });
            }

            this._layoutSearch.activate();
        }
    };

    _openMenu = () => {
        this._collapseNavigationItems();

        this._overlay.show();
        this._rootEl.classList.add(openedMod);
        lockPageScrolling(this._bodyEl);
    };

    _closeMenu = () => {
        this._overlay.hide();
        this._rootEl.classList.remove(openedMod);
        unlockPageScrolling(this._bodyEl);
    };

    _headingClickHandler = (e) => {
        const linkEl = _closest(e.target, `.js-${blockName}__logo-link`);

        if (!linkEl) {
            this._closeMenu();
        }
    };

    _asideArrowTouchendHandler = (e) => {
        const navigationItemEl = _closest(e._caughtTarget_, navigationItemSelector);
        const navigationDropdownEl = navigationItemEl.querySelector(`.js-${blockName}__dropdown`);

        if (navigationItemEl.classList.contains(navigationItemExpandedMod)) {
            navigationDropdownEl.style.height = _getCSSProp(navigationDropdownEl, 'height');

            navigationItemEl.classList.add(navigationItemAnimatingMod);
            navigationItemEl.classList.remove(navigationItemExpandedMod);

            _reflow(navigationDropdownEl);
            navigationDropdownEl.style.height = '0px';
        } else {
            navigationDropdownEl.style.height = '0px';

            navigationItemEl.classList.add(navigationItemAnimatingMod);
            navigationItemEl.classList.add(navigationItemExpandedMod);

            _reflow(navigationDropdownEl);
            navigationDropdownEl.style.height = `${navigationDropdownEl.scrollHeight}px`;
        }

        if (_getData(navigationDropdownEl, 'transitionEndHandler') !== 'true') {
            _onTransitionEnd(navigationDropdownEl, () => {
                navigationItemEl.classList.remove(navigationItemAnimatingMod);
                navigationDropdownEl.style.height = '';

                _removeData(navigationDropdownEl, 'transitionEndHandler');
            });

            _setData(navigationDropdownEl, 'transitionEndHandler', 'true');
        }
    };

    _linkMouseoverHandler = (e) => {
        const currentLinkEl = e._caughtTarget_;
        const that = this;

        function mouseoutHandler(mouseoutEvent) {
            const rTarget = mouseoutEvent.relatedTarget;

            if (!rTarget || (rTarget !== currentLinkEl && !currentLinkEl.contains(rTarget))) {
                clearOpenTimeout();
            }
        }

        function clearOpenTimeout() {
            _off(document, 'mouseout', mouseoutHandler);
            _off(currentLinkEl, 'pointerdown', clearOpenTimeout);
            clearTimeout(that._openTimeout);

            that._openTimeout = null;
        }

        if (!this._openTimeout) {
            const navigationItemEl = _closest(currentLinkEl, navigationItemSelector);

            if (!navigationItemEl.classList.contains(navigationItemExpandedMod)) {
                this._openTimeout = setTimeout(() => {
                    clearOpenTimeout();
                    this._expandNavigationItem(navigationItemEl);
                }, 150);

                _on(document, 'mouseout', mouseoutHandler);
                _on(currentLinkEl, 'pointerdown', clearOpenTimeout, { once: true });
            }
        }
    };

    _expandNavigationItem = (navigationItemEl) => {
        if (!navigationItemEl.classList.contains(navigationItemExpandedMod)) {
            this._collapseNavigationItems();

            LayoutSearch.hideResults();

            navigationItemEl.classList.add(navigationItemExpandedMod);
            _on(navigationItemEl, 'mouseout', this._navigationItemMouseoutHandler);
        }
    };

    _collapseNavigationItem = (navigationItemEl) => {
        _off(navigationItemEl, 'mouseover', this._clearCloseTimeout);
        _off(navigationItemEl, 'mouseout', this._navigationItemMouseoutHandler);
        navigationItemEl.classList.remove(navigationItemExpandedMod);
    };

    _navigationItemMouseoutHandler = (e) => {
        const rTarget = e.relatedTarget;
        const navigationItemEl = e.currentTarget;

        if (!rTarget || (rTarget !== navigationItemEl && !navigationItemEl.contains(rTarget))) {
            _on(navigationItemEl, 'mouseover', this._clearCloseTimeout, { once: true });

            this._closeTimeout = setTimeout(() => {
                _off(navigationItemEl, 'mouseover', this._clearCloseTimeout);
                this._collapseNavigationItem(navigationItemEl);
            }, 200);
        }
    };

    _clearCloseTimeout = () => {
        clearTimeout(this._closeTimeout);
        this._closeTimeout = null;
    };

    _collapseNavigationItems = () => {
        const openedListItemsSelector = `${navigationItemSelector}.${navigationItemExpandedMod}`;
        const openedListItems = _toArray(this._rootEl.querySelectorAll(openedListItemsSelector));

        openedListItems.forEach(this._collapseNavigationItem);
    };
}

components.add(`js-${blockName}`, element => new LayoutMenu(element));
