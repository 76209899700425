import { _isNil } from '../../common/_types';

export default function(data) {
    return (data.tags || [])
        .map((tagData) => {
            const className = 'offers-filters-panel__tag js-offers-filters-panel__tag';
            const label = `<span class="offers-filters-panel__tag-label">${tagData.label}</span>`;
            const removeIcon = '<i class="offers-filters-panel__tag-remove js-offers-filters-panel__tag-remove"></i>';
            const attrs = [
                `data-type="${data.tagType}"`,
            ];

            if (!_isNil(tagData.id)) {
                attrs.push(`data-id="${tagData.id}"`);
            }

            if (!_isNil(tagData.field)) {
                attrs.push(`data-field="${tagData.field}"`);
            }

            if (!_isNil(tagData.value)) {
                attrs.push(`data-value="${tagData.value}"`);
            }

            return `<div class="${className}" ${attrs.join(' ')}>${label}${removeIcon}</div>`;
        })
        .join('');
}
