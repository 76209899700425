import StoreInterface from './__store';

const URL_DATA_SORTING_KEY = 'finder';
const URL_DATA_DIRECTION_KEY = 'direction';

const OffersFiltersPanelStoreSorting = {
    _state: {
        sorting: null,
    },

    _sortingTypes: [],

    setSorting(value) {
        if (this._state.sorting !== value && this._hasSorting(value)) {
            this._state.sorting = value;
            this.eventEmitter.emit('change');
        }
    },

    _hasSorting(value) {
        return this._sortingTypes.some(sortingType => sortingType.value === value);
    },

    getSortings() {
        return this._sortingTypes.map(sortingType => ({
            label: sortingType.label,
            value: sortingType.value,
            isSelected: (sortingType.value === this._state.sorting),
        }));
    },

    getCurrentName() {
        const current = this._sortingTypes.filter(sortingType => sortingType.value === this._state.sorting)[0];
        return current ? current.label : '';
    },

    getCurrentValue() {
        return this._state.sorting;
    },

    getValidUrlDataKeys() {
        return [URL_DATA_SORTING_KEY, URL_DATA_DIRECTION_KEY];
    },

    fromUrlData(data) {
        if (this.isInited()) {
            if (data[URL_DATA_SORTING_KEY]) {
                this.setSorting(data[URL_DATA_SORTING_KEY]);
            } else {
                this.toDefaultState();
            }
        }
    },

    getUrlData() {
        return this.isInDefaultState() ? {} : this.getRequestData();
    },

    getRequestData() {
        return {
            [URL_DATA_SORTING_KEY]: this.getCurrentValue(),
            [URL_DATA_DIRECTION_KEY]: 'desc',
        };
    },

    getTags() {
        return [{ label: this.getCurrentName() }];
    },

    init({ sortingTypes }) {
        if (!this.isInited()) {
            let defaultValue;
            let selectedValue;

            this._sortingTypes = sortingTypes.map((sortingType) => {
                if (sortingType.isDefault) {
                    defaultValue = sortingType.value;
                }

                if (sortingType.isSelected) {
                    selectedValue = sortingType.value;
                }

                return {
                    label: sortingType.label,
                    value: sortingType.value,
                };
            });

            if (!defaultValue) {
                defaultValue = this._sortingTypes[0].value;
            }

            this._state.sorting = defaultValue;

            StoreInterface.init(this);

            if (selectedValue) {
                this._state.sorting = selectedValue;
            }
        }
    },
};

export default StoreInterface.extend(OffersFiltersPanelStoreSorting);
