import { _EventEmitter } from '../../common/_events';
import StoreInterface from './__store';

const URL_DATA_KEY = 'categories';

const OffersFiltersPanelStoreCategories = {
    _state: {
        selectedCategories: {},
    },

    _categories: [],

    selectCategory(rawCategoryId) {
        const categoryId = String(rawCategoryId);

        if (!this.isCategorySelected(categoryId) && this.isCategoryExists(categoryId)) {
            this._state.selectedCategories[categoryId] = true;
            this.eventEmitter.emit('change');
        }
    },

    unSelectCategory(rawCategoryId) {
        const categoryId = String(rawCategoryId);

        if (this.isCategorySelected(categoryId)) {
            delete this._state.selectedCategories[categoryId];
            this.eventEmitter.emit('change');
        }
    },

    getCategories() {
        return this._categories.map(({ id, name }) => ({
            id,
            name,
            isSelected: this.isCategorySelected(id),
        }));
    },

    isCategorySelected(rawCategoryId) {
        const categoryId = String(rawCategoryId);

        return this._state.selectedCategories[categoryId] === true;
    },

    isCategoryExists(rawCategoryId) {
        const categoryId = String(rawCategoryId);

        return this._categories.some(({ id }) => id === categoryId);
    },

    getValidUrlDataKeys() {
        return [URL_DATA_KEY];
    },

    fromUrlData(data) {
        if (this.isInited()) {
            const currentState = JSON.stringify(this._state);
            const originalEventEmitter = this.eventEmitter;

            this.eventEmitter = new _EventEmitter();
            this.toDefaultState();

            if (data[URL_DATA_KEY]) {
                const valueFromUrl = data[URL_DATA_KEY];
                const categories = Array.isArray(valueFromUrl)
                    ? valueFromUrl.slice()
                    : [valueFromUrl];
                const isValidRe = /^[0-9]+$/;

                categories.forEach((categoryId) => {
                    if (isValidRe.test(categoryId)) {
                        this.selectCategory(categoryId);
                    }
                });
            }

            this.eventEmitter = originalEventEmitter;

            if (JSON.stringify(this._state) !== currentState) {
                originalEventEmitter.emit('change');
            }
        }
    },

    getUrlData() {
        return this.isInDefaultState() ? {} : this.getRequestData();
    },

    getRequestData() {
        return {
            [URL_DATA_KEY]: Object.keys(this._state.selectedCategories),
        };
    },

    getTags() {
        const tags = [];

        this._categories.forEach(({ id, name }) => {
            if (this.isCategorySelected(id)) {
                tags.push({
                    id,
                    label: name,
                });
            }
        });

        return tags;
    },

    init({ categories }) {
        if (!this.isInited()) {
            this._categories = categories.map(category => ({
                id: String(category.id),
                name: String(category.name),
            }));

            StoreInterface.init(this);
        }
    },
};

export default StoreInterface.extend(OffersFiltersPanelStoreCategories);
