import { _createEl } from '../../common/_create-el';
import backTemplate from './__back-el';

export default (data) => {
    const blockName = 'offers-filters-panel';
    const filtersInnerClass = `${blockName}__filters-inner`;
    const ctrlClass = `${blockName}__controls`;
    const ctrlBtnClass = `${blockName}__controls-button`;

    return _createEl('div', 'offers-filters-panel__menus-container-scrollable', [
        _createEl('div', 'offers-filters-panel__menus-container-inner', [].concat(
            [backTemplate({ header: data.header })],
            data.menus.map((menuData) => {
                const menuMod = `${blockName}__menu_for_${menuData.name}`;
                const componentClass = `js-${blockName}__${menuData.name}`;

                return _createEl('div', `offers-filters-panel__menu js-offers-filters-panel__menu ${menuMod}`, [
                    _createEl('div', 'offers-filters-panel__button js-offers-filters-panel__button', [
                        _createEl('i', 'offers-filters-panel__button-icon'),
                        _createEl('div', 'offers-filters-panel__button-text', [menuData.header]),
                        _createEl('div', 'offers-filters-panel__button-clear js-offers-filters-panel__button-clear', [
                            menuData.clearLabel,
                        ]),
                        _createEl('i', 'offers-filters-panel__button-state'),
                    ]),
                    _createEl('div', 'offers-filters-panel__filters js-offers-filters-panel__filters', [
                        _createEl('div', `${filtersInnerClass} js-${filtersInnerClass} ${componentClass}`, {
                            'data-component': '',
                            'data-type': menuData.name,
                            'data-header': menuData.header,
                        }),
                        _createEl('div', ctrlClass, [
                            _createEl('button', `btn btn_size_lg ${ctrlBtnClass} js-${ctrlBtnClass}`, [
                                data.applyButtonLabel,
                            ]),
                        ]),
                    ]),
                ]);
            }),
        )),
        _createEl('div', ctrlClass, [
            _createEl('button', `btn btn_size_lg ${ctrlBtnClass} js-${ctrlBtnClass} ${ctrlBtnClass}_type_main-apply`, [
                data.applyButtonLabel,
            ]),
            _createEl('button', `btn btn_size_lg ${ctrlBtnClass} js-${ctrlBtnClass} ${ctrlBtnClass}_type_main-reset`, [
                data.resetButtonLabel,
            ]),
        ]),
    ]);
};
