import components from '../../common/_components';
import { _isElementVisible, _toArray } from '../../common/_core';
import { _on } from '../../common/_events';
import StickyElement from '../../common/_sticky-element';
import { scrollToTop } from '../../common/_scroll-page';
import { openInAsidePanel, onFiltersChange } from '../offers-filters-panel/offers-filters-panel';
import SortingStore from '../offers-filters-panel/__store-sorting';

import './offers-filters-headline.scss';

const blockName = 'offers-filters-headline';

function getDataFromSelect(selectEl) {
    return _toArray(selectEl.querySelectorAll('option')).map(optionEl => ({
        label: optionEl.textContent,
        value: optionEl.value,
        isSelected: optionEl.selected,
        isDefault: optionEl.hasAttribute('data-default'),
    }));
}

components.add(`js-${blockName}`, (rootEl) => {
    const selects = _toArray(rootEl.querySelectorAll(`.js-${blockName}__select`));
    const mobileAreaEl = rootEl.querySelector(`.js-${blockName}__mobile`);

    SortingStore.init({ sortingTypes: getDataFromSelect(selects[0]) });
    SortingStore.eventEmitter.subscribe('change', () => {
        const desktopSortByEl = rootEl.querySelector(`.js-${blockName}__desktop-sort-by`);
        const sorting = SortingStore.getCurrentValue();

        desktopSortByEl.textContent = SortingStore.getCurrentName();
        selects.forEach((selectEl) => { selectEl.value = sorting; });
    });

    selects.forEach((selectEl) => {
        _on(selectEl, 'change', e => SortingStore.setSorting(e.currentTarget.value));
    });

    _on(rootEl, 'click', `.js-${blockName}__mobile-menu-button`, () => {
        openInAsidePanel();
    }, { passive: true });

    onFiltersChange(() => {
        if (_isElementVisible(mobileAreaEl)) {
            scrollToTop(mobileAreaEl);
        }
    });

    StickyElement.installTo(rootEl.querySelector(`.js-${blockName}__mobile-fixed`), mobileAreaEl);
});
