import bugsnag from '../../common/_bugsnag-client';
import { _EventEmitter } from '../../common/_events';
import StoreInterface from './__store';

const DEFAULT_PROPERTY_VALUE = null;
const DEFAULT_PROPERTIES_VALUE = [];

const urlDataKeys = [];

const OffersFiltersPanelStoreOffers = {
    _state: {},

    _labels: {},

    addValueTo(field, rawValue) {
        const value = String(rawValue);

        if (!this.containsValue(field, value) && this.isValueValue(field, value)) {
            const descriptor = this._state[field];

            if (typeof descriptor === 'undefined') {
                bugsnag.notify(`Field ${field} is not described in state of OffersFiltersPanelStoreOffers`);
                return;
            }

            if (Array.isArray(descriptor)) {
                descriptor.push(value);
            } else {
                this._state[field] = value;
            }

            this.eventEmitter.emit('change');
        }
    },

    deleteValueFrom(field, rawValue) {
        const value = String(rawValue);

        if (this.containsValue(field, value)) {
            const descriptor = this._state[field];

            if (typeof descriptor === 'undefined') {
                bugsnag.notify(`Field ${field} is not described in state of OffersFiltersPanelStoreOffers`);
                return;
            }

            if (Array.isArray(descriptor)) {
                this._state[field] = this._state[field].filter(val => val !== value);
            } else {
                this._state[field] = DEFAULT_PROPERTY_VALUE;
            }

            this.eventEmitter.emit('change');
        }
    },

    containsValue(field, rawValue) {
        const value = String(rawValue);

        return Array.isArray(this._state[field])
            ? this._state[field].indexOf(value) !== -1
            : this._state[field] !== DEFAULT_PROPERTY_VALUE;
    },

    isValueValue(field, rawValue) {
        return typeof this._labels[this._getLabelKey(field, rawValue)] !== 'undefined';
    },

    getValidUrlDataKeys() {
        return urlDataKeys.slice();
    },

    fromUrlData(data) {
        if (this.isInited()) {
            const currentState = JSON.stringify(this._state);
            const originalEventEmitter = this.eventEmitter;
            const fields = Object.keys(this._state)
                .filter(field => Object.prototype.hasOwnProperty.call(data, field));

            this.eventEmitter = new _EventEmitter();
            this.toDefaultState();

            fields.forEach((field) => {
                const values = Array.isArray(data[field])
                    ? data[field].slice()
                    : [data[field]];

                values.forEach(value => this.addValueTo(field, value));
            });

            this.eventEmitter = originalEventEmitter;

            if (JSON.stringify(this._state) !== currentState) {
                originalEventEmitter.emit('change');
            }
        }
    },

    getUrlData() {
        return this.isInDefaultState() ? {} : this.getRequestData();
    },

    getRequestData() {
        const data = {};

        Object.keys(this._state).forEach((fieldName) => {
            const descriptor = this._state[fieldName];

            if (Array.isArray(descriptor)) {
                if (descriptor.length > 0) {
                    data[fieldName] = descriptor.slice();
                }
            } else if (descriptor !== DEFAULT_PROPERTY_VALUE) {
                data[fieldName] = descriptor;
            }
        });

        return data;
    },

    getTags() {
        const tags = [];
        const appendTag = (field, value) => {
            tags.push({
                field,
                value,
                label: this._labels[this._getLabelKey(field, value)],
            });
        };

        Object.keys(this._state).forEach((fieldName) => {
            const descriptor = this._state[fieldName];

            if (Array.isArray(descriptor)) {
                descriptor.forEach(value => appendTag(fieldName, value));
            } else if (descriptor !== DEFAULT_PROPERTY_VALUE) {
                appendTag(fieldName, descriptor);
            }
        });

        return tags;
    },

    _getLabelKey(field, value) {
        return `${field}||${value}`;
    },

    init({ filters }) {
        if (!this.isInited()) {
            filters.forEach(({ field, value, label }) => {
                if (!Object.prototype.hasOwnProperty.call(this._state, field)) {
                    this._state[field] = DEFAULT_PROPERTY_VALUE;
                } else if (this._state[field] === DEFAULT_PROPERTY_VALUE) {
                    this._state[field] = DEFAULT_PROPERTIES_VALUE;
                }

                if (urlDataKeys.indexOf(field) === -1) {
                    urlDataKeys.push(field);
                }

                this._labels[this._getLabelKey(field, value)] = label;
            });

            StoreInterface.init(this);
        }
    },
};

export default StoreInterface.extend(OffersFiltersPanelStoreOffers);
