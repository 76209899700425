import { throttle } from 'throttle-debounce';
import { _on, _off } from './_events';

const triggers = [];

class ScrollTrigger {
    constructor(el, { offset = 10 }) {
        this._el = el;
        this._offset = offset;

        this.refresh();
    }

    getRect = () => {
        if (this._rect) {
            return {
                top: this._rect.top,
                left: this._rect.left,
                right: this._rect.right,
                bottom: this._rect.bottom,
            };
        }

        return null;
    };

    isAttached = () => document.body.contains(this._el);

    refresh = () => {
        const windowTop = window.pageYOffset;
        const windowLeft = window.pageXOffset;
        const elRect = this._el.getBoundingClientRect();

        if (elRect.width > 0 || elRect.height > 0) {
            this._rect = {
                top: windowTop + elRect.top - this._offset,
                left: windowLeft + elRect.left - this._offset,
                bottom: windowTop + elRect.bottom + this._offset,
                right: windowLeft + elRect.right + this._offset,
            };
        } else {
            this._rect = null;
        }
    }
}

function checkScrollTriggers() {
    const windowWidth = document.documentElement.clientWidth;
    const windowHeight = document.documentElement.clientHeight;
    const windowTop = window.pageYOffset;
    const windowLeft = window.pageXOffset;

    triggers
        .slice()
        .forEach(([scrollTrigger, callback], i) => {
            const rect = scrollTrigger.getRect();

            if (rect && windowTop + windowHeight > rect.top
                    && windowTop < rect.bottom
                    && windowLeft + windowWidth > rect.left
                    && windowLeft < rect.right) {
                triggers.splice(i, 1);
                callback();
            }
        });

    _checkHandlers();
}

function refreshScrollTriggers() {
    triggers
        .slice()
        .forEach(([scrollTrigger], i) => {
            if (scrollTrigger.isAttached()) {
                scrollTrigger.refresh();
            } else {
                triggers.splice(i, 1);
            }
        });

    _checkHandlers();
}

const scrollHandler = throttle(200, checkScrollTriggers);
const resizeHandler = throttle(200, () => {
    refreshScrollTriggers();
    checkScrollTriggers();
});

let isHandlersAttached = false;

function _checkHandlers() {
    if (triggers.length > 0 && !isHandlersAttached) {
        _on(window, 'scroll', scrollHandler);
        _on(window, 'resize', resizeHandler);

        isHandlersAttached = true;
    } else if (!triggers.length && isHandlersAttached) {
        _off(window, 'scroll', scrollHandler);
        _off(window, 'resize', resizeHandler);

        isHandlersAttached = false;
    }
}

function addScrollTrigger(el, options = {}, cb) {
    triggers.push([
        new ScrollTrigger(el, options),
        cb,
    ]);

    _checkHandlers();
}

export { addScrollTrigger, checkScrollTriggers, refreshScrollTriggers };
