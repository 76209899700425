import { _closest, _getCSSProp, _getData, _parseHTML } from '../../common/_core';
import { _sendRequest } from '../../common/_ajax';
import { _on, _trigger } from '../../common/_events';
import './load-more.scss';

const blockName = 'load-more';

_on(document, 'click', `.js-${blockName}__button`, (e) => {
    const buttonEl = e._caughtTarget_;
    const containerEl = _closest(buttonEl, `.js-${blockName}`);
    const loadingEl = _parseHTML(`<i class="fa fa-spin fa-circle-notch ${blockName}__spinner"></i>`)[0];
    const loadingMod = `${blockName}_loading`;
    const url = _getData(containerEl, 'loadUrl');

    if (!containerEl.classList.contains(loadingMod) && url) {
        const containerHeight = parseFloat(_getCSSProp(containerEl, 'height'));

        containerEl.style.height = `${containerHeight}px`;

        containerEl.classList.add(loadingMod);
        containerEl.appendChild(loadingEl);

        _sendRequest({
            url,
            success: (data) => {
                const targetSelector = _getData(containerEl, 'loadTarget');
                const documentFragment = document.createDocumentFragment();

                let parentEl;

                _parseHTML(data).forEach(contentNode => documentFragment.appendChild(contentNode));

                if (targetSelector) {
                    parentEl = document.querySelector(targetSelector);

                    parentEl.appendChild(documentFragment);
                    containerEl.parentNode.removeChild(containerEl);
                } else {
                    parentEl = containerEl.parentNode;
                    parentEl.replaceChild(documentFragment, containerEl);
                }

                _trigger(parentEl, '_nodes-inserted');
            },
            error: () => {
                if (loadingEl.parentNode) {
                    loadingEl.parentNode.removeChild(loadingEl);
                }

                containerEl.classList.remove(loadingMod);
                containerEl.style.height = '';
            },
        });
    }

    e.preventDefault();
});
