import components from '../../common/_components';
import { _on } from '../../common/_events';
import { _getData, _toArray } from '../../common/_core';
import { _sendRequest, _serialize } from '../../common/_ajax';
import { addScrollTrigger, checkScrollTriggers } from '../../common/_scroll-page-trigger';
import Tooltip from '../tooltip/tooltip';

import './newsletter-primary.scss';

const blockName = 'newsletter-primary';

components.add(`js-${blockName}`, (element) => {
    _on(element, 'submit', `js-${blockName}__form`, (e) => {
        const formEl = e.target;
        const inputEl = formEl.querySelector(`.js-${blockName}__input`);
        const submitButtons = formEl.querySelectorAll(`.js-${blockName}__submit`);
        const tooltip = new Tooltip(inputEl);

        e.preventDefault();

        tooltip.hide();

        if (!inputEl.disabled) {
            const formData = _serialize(formEl);

            inputEl.disabled = true;

            _sendRequest({
                method: 'POST',
                url: _getData(formEl, 'ajaxUrl'),
                data: formData,
                success: (responseJSON) => {
                    _toArray(submitButtons).forEach((submitButtonEl) => {
                        submitButtonEl.disabled = true;
                    });

                    inputEl.placeholder = responseJSON.data.message;
                    inputEl.value = '';
                },
                error: (responseJSON) => {
                    tooltip.show(responseJSON.data.message);
                    inputEl.disabled = false;
                },
            });
        }
    });

    addScrollTrigger(element, { offset: 0 }, () => {
        element.classList.add(`${blockName}_shown`);
    });

    checkScrollTriggers();
});
