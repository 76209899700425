import components from '../../common/_components';
import { _getData } from '../../common/_core';
import { _sendRequest } from '../../common/_ajax';
import { onFiltersChange } from '../offers-filters-panel/offers-filters-panel';

import './page-offers-index.scss';

const blockName = 'page-offers-index';

components.add(`js-${blockName}`, (rootEl) => {
    let offersRequest;

    onFiltersChange((data = {}) => {
        if (offersRequest) {
            offersRequest.abort();
        }

        offersRequest = _sendRequest({
            url: _getData(rootEl, 'offersUrl'),
            data,
            success: (response) => {
                rootEl.querySelector(`.js-${blockName}__offers-list`).innerHTML = response;
                components.init();
            },
        });
    });
});
