import StoreInterface from './__store';

const URL_DATA_KEY = 'minimum_order_value';

const OffersFiltersPanelStoreMinimalOrder = {
    _state: {
        minimalOrder: null,
    },

    _minValue: 0,
    _maxValue: Infinity,
    _label: '',

    setMinimalOrder(value) {
        if (this._state.minimalOrder !== value && this.isValidValue(value)) {
            this._state.minimalOrder = value;
            this.eventEmitter.emit('change');
        }
    },

    getMinimalOrder() {
        return this._state.minimalOrder;
    },

    isValidValue(value) {
        return typeof value === 'number'
            && value <= this._maxValue
            && value >= this._minValue;
    },

    getValidUrlDataKeys() {
        return [URL_DATA_KEY];
    },

    fromUrlData(data) {
        if (this.isInited()) {
            if (data[URL_DATA_KEY] && /^[0-9]+$/.test(data[URL_DATA_KEY])) {
                this.setMinimalOrder(Number(data[URL_DATA_KEY]));
            } else {
                this.toDefaultState();
            }
        }
    },

    getUrlData() {
        return this.isInDefaultState() ? {} : this.getRequestData();
    },

    getRequestData() {
        return {
            [URL_DATA_KEY]: this._state.minimalOrder,
        };
    },

    getTags() {
        return [{ label: this._label.replace('{{value}}', this.getMinimalOrder()) }];
    },

    init({ minValue, maxValue, defaultValue, label }) {
        if (!this.isInited()) {
            this._state.minimalOrder = defaultValue;

            this._minValue = minValue;
            this._maxValue = maxValue;
            this._label = label;

            StoreInterface.init(this);
        }
    },
};

export default StoreInterface.extend(OffersFiltersPanelStoreMinimalOrder);
